import * as React from 'react';
import { Switch } from '@headlessui/react';

/**
 * Props for the Toggle component
 */
export interface ToggleProps {
  /** Whether the toggle is checked/active */
  checked: boolean;
  /** Callback when the toggle state changes */
  onChange: (checked: boolean) => void;
  /** Label text to display next to the toggle */
  label: string;
  /** Optional ID for the toggle, used for accessibility */
  id?: string;
  /** Optional flag to disable the toggle */
  disabled?: boolean;
}

/**
 * A reusable toggle switch component
 */
export const Toggle: React.FC<ToggleProps> = ({
  checked,
  onChange,
  label,
  id,
  disabled = false
}) => {
  return (
    <div className="flex items-center">
      <Switch
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        id={id}
        className={`${
          checked ? 'bg-blue-800' : 'bg-gray-200 dark:bg-neutral-700'
        } relative inline-flex h-6 w-11 items-center rounded-full transition-colors ${
          disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
        }`}
      >
        <span className="sr-only">{label}</span>
        <span
          className={`${
            checked ? 'translate-x-6' : 'translate-x-1'
          } inline-block h-4 w-4 transform rounded-full bg-white transition`}
        />
      </Switch>
      <label 
        htmlFor={id} 
        className={`ml-2 text-sm text-gray-600 dark:text-neutral-400 ${disabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'}`}
      >
        {label}
      </label>
    </div>
  );
};

export default Toggle;
