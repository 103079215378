import { memo } from 'react';
import { motion } from 'framer-motion';
import { Icon } from '../Icon';
import type { MetricOption } from '../../types/api';

interface SearchMetricsConfigProps {
  isVisible: boolean;
  availableMetrics: MetricOption[];
  visibleMetrics: string[];
  onVisibleMetricsChange: (metrics: string[]) => void;
  onClose: () => void;
  defaultMetrics: string[];
}

/**
 * SearchMetricsConfig component for customizing which metrics are displayed on listing cards
 */
export const SearchMetricsConfig = memo(({
  isVisible,
  availableMetrics,
  visibleMetrics,
  onVisibleMetricsChange,
  onClose,
  defaultMetrics
}: SearchMetricsConfigProps) => {
  if (!isVisible) return null;

  // Group metrics by category
  const groupedMetrics = availableMetrics.reduce((acc, metric) => {
    const group = metric.group || 'basic';
    if (!acc[group]) {
      acc[group] = [];
    }
    acc[group].push(metric);
    return acc;
  }, {} as Record<string, MetricOption[]>);

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white dark:bg-gray-800 rounded-lg shadow-xl max-w-2xl w-full">
        <div className="p-6">
          <div className="flex justify-between items-center mb-6">
            <h3 className="text-lg font-medium text-gray-900 dark:text-white">
              Attributes to Display
            </h3>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-500 dark:hover:text-gray-300"
            >
              <Icon name="X" size={16} className="text-gray-400 dark:text-neutral-500" />
            </button>
          </div>

          <div className="mb-6">
            <div className="space-y-4">
              {Object.entries(groupedMetrics).map(([group, metrics]) => (
                <div key={group} className="border-b border-gray-200 dark:border-gray-700 pb-4 last:border-b-0">
                  <h4 className="text-sm font-medium text-gray-700 dark:text-gray-300 uppercase mb-3">
                    {group === 'basic' ? 'Basic Information' : 'Equipment'}
                  </h4>
                  <div className="grid grid-cols-2 sm:grid-cols-3 gap-3">
                    {metrics.map((metric) => (
                      <label key={metric.key} className="flex items-center space-x-2">
                        <input
                          type="checkbox"
                          checked={visibleMetrics.includes(metric.key)}
                          onChange={(e) => {
                            if (e.target.checked) {
                              onVisibleMetricsChange([...visibleMetrics, metric.key]);
                            } else {
                              onVisibleMetricsChange(visibleMetrics.filter(m => m !== metric.key));
                            }
                          }}
                          className="rounded text-blue-800 focus:ring-blue-800"
                        />
                        <span className="text-sm text-gray-900 dark:text-white">
                          {metric.label}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="mt-6 flex justify-end gap-3">
            <button
              onClick={() => onVisibleMetricsChange([...defaultMetrics])}
              className="px-3 py-1 text-sm text-gray-600 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-200"
            >
              Reset to Default
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-blue-800 text-white rounded hover:bg-blue-900 text-sm font-medium"
            >
              Done
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});
