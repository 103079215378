import { Link } from 'react-router-dom';
import { Icon } from '../Icon';
import { cn, buttonStyles } from '../../lib/utils';

interface SearchHeaderProps {
  favoritesCount: number;
  onOpenFilterModal: () => void;
}

/**
 * Header component for the aircraft search page
 * Contains the logo/title, favorites counter, and filter button
 */
export const SearchHeader = ({
  favoritesCount,
  onOpenFilterModal
}: SearchHeaderProps) => {
  return (
    <div className="py-4 sm:py-6 flex items-center justify-between relative z-[60]">
      <div className="flex items-center">
        <h1 className="text-3xl font-bold tracking-tight text-blue-900 dark:text-white flex items-center">
          <span className="font-extrabold tracking-tight ml-1">All</span>
          <span className="font-light ml-[5px] tracking-normal">the</span>
          <span className="font-extrabold tracking-tight ml-1">Planes</span>
          <span className="inline-flex items-center justify-center h-9 relative" aria-hidden="true">            
          </span>
        </h1>
      </div>
      
      <div className="flex items-center gap-2 sm:gap-3">
        {/* Heart icon */}
        <Link to="/favorites" className="flex items-center gap-1">
          <Icon name="Heart" size={20} className="text-red-500 fill-red-500" />
          <span className="text-sm font-medium">{favoritesCount}</span>
        </Link>
        
        {/* Filter button */}
        <button
          onClick={onOpenFilterModal}
          className={cn(
            buttonStyles.base,
            buttonStyles.rounded,
            buttonStyles.shadow,
            'inline-flex items-center justify-center h-10 px-4 text-sm font-semibold bg-blue-800 hover:bg-blue-900 text-white'
          )}
        >
          <Icon name="Filter" size={16} className="mr-2" />
          Filters
        </button>
      </div>
    </div>
  );
};
