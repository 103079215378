import * as React from 'react';
import { RangeSlider } from '@/components/common/range-slider';
import type { RangeConfig } from '@/shared/range-config';

/**
 * Props for the Specifications component
 */
export interface SpecificationsProps {
  /** Engine time range values */
  engineTimeRange: [number, number];
  /** Useful load range values */
  usefulLoadRange: [number, number];
  /** Range configurations */
  rangeConfigs: { [key: string]: RangeConfig };
  /** Callback for engine time range changes */
  onEngineTimeChange: (values: [number, number]) => void;
  /** Callback for useful load range changes */
  onUsefulLoadChange: (values: [number, number]) => void;
}

/**
 * Component for filtering by aircraft specifications
 */
export const Specifications: React.FC<SpecificationsProps> = ({
  engineTimeRange,
  usefulLoadRange,
  rangeConfigs,
  onEngineTimeChange,
  onUsefulLoadChange
}) => {
  // Use local state for smoother slider interaction - Engine Time
  const [localEngineTimeRange, setLocalEngineTimeRange] = React.useState<[number, number]>(engineTimeRange);
  
  // Use local state for smoother slider interaction - Useful Load
  const [localUsefulLoadRange, setLocalUsefulLoadRange] = React.useState<[number, number]>(usefulLoadRange);
  
  // Keep local state in sync with props
  React.useEffect(() => {
    setLocalEngineTimeRange(engineTimeRange);
  }, [engineTimeRange]);
  
  React.useEffect(() => {
    setLocalUsefulLoadRange(usefulLoadRange);
  }, [usefulLoadRange]);

  return (
    <div className="space-y-6">
      <div>
        <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100 mb-4">Engine Type</h3>
        <p className="text-[13px] text-gray-500 dark:text-neutral-400 italic">Coming soon</p>
      </div>

      <div>
        <RangeSlider
          value={localEngineTimeRange}
          onChange={(values) => {
            setLocalEngineTimeRange(values as [number, number]);
            onEngineTimeChange(values as [number, number]);
          }}
          min={rangeConfigs.engineTimeRange.min}
          max={rangeConfigs.engineTimeRange.max}
          step={rangeConfigs.engineTimeRange.step}
          label="Total Engine Time"
          formatMin={(val) => `${val} hrs`}
          formatMax={(val) => val === rangeConfigs.engineTimeRange.max ? `${val}+ hrs` : `${val} hrs`}
        />
      </div>

      <div>
        <RangeSlider
          value={localUsefulLoadRange}
          onChange={(values) => {
            setLocalUsefulLoadRange(values as [number, number]);
            onUsefulLoadChange(values as [number, number]);
          }}
          min={rangeConfigs.usefulLoadRange.min}
          max={rangeConfigs.usefulLoadRange.max}
          step={rangeConfigs.usefulLoadRange.step}
          label="Useful Load"
          formatMin={(val) => `${val} lbs`}
          formatMax={(val) => val === rangeConfigs.usefulLoadRange.max ? `${val}+ lbs` : `${val} lbs`}
        />
      </div>

      <div>
        <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100 mb-4">Seats</h3>
        <p className="text-[13px] text-gray-500 dark:text-neutral-400 italic">Coming soon</p>
      </div>

      <div>
        <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100 mb-4">Flight Rules</h3>
        <p className="text-[13px] text-gray-500 dark:text-neutral-400 italic">Coming soon</p>
      </div>
    </div>
  );
};

export default Specifications;
