import * as React from 'react';
import { PillGroup, SinglePillGroup } from '@/components/common/pills';
import type { Option } from '@/types/api';

/**
 * Props for the AircraftType component
 */
export interface AircraftTypeProps {
  /** The currently selected category */
  selectedCategory: Option | null;
  /** Callback when category changes */
  onCategoryChange: (selected: Option | null) => void;
  /** Currently selected intended uses */
  selectedIntendedUses: Option[];
  /** Callback when intended uses change */
  onIntendedUseChange: (selected: Option | Option[] | null) => void;
  /** Available filters data */
  availableFilters: {
    categories?: Option[];
    intendedUses?: Option[];
  };
}

/**
 * Component for filtering by aircraft type and mission
 */
export const AircraftType: React.FC<AircraftTypeProps> = ({
  selectedCategory,
  onCategoryChange,
  selectedIntendedUses,
  onIntendedUseChange,
  availableFilters
}) => {
  return (
    <div className="space-y-6">
      <div>
        <div className="flex justify-between items-baseline mb-2">
          <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Category</h3>
          {selectedCategory && (
            <button
              onClick={() => onCategoryChange(null)}
              className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
            >
              Clear
            </button>
          )}
        </div>
        <SinglePillGroup
          options={(availableFilters.categories || [])}
          selectedValue={selectedCategory?.value?.toString() || null}
          onChange={onCategoryChange}
          maxDisplay={10}
          emptyMessage="No category options available"
          isModelSection={false}
        />
      </div>
      
      <div>
        <div className="flex justify-between items-baseline mb-2">
          <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Mission Type</h3>
          {selectedIntendedUses.length > 0 && (
            <button
              onClick={() => onIntendedUseChange(null)}
              className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
            >
              Clear All
            </button>
          )}
        </div>
        <PillGroup
          options={(availableFilters.intendedUses || [])}
          selectedValues={selectedIntendedUses.map(u => String(u.value))}
          onChange={onIntendedUseChange}
          maxDisplay={10}
          emptyMessage="No mission type options available"
          isModelSection={false}
        />
      </div>
    </div>
  );
};

export default AircraftType;
