import * as React from 'react';
import { motion } from 'framer-motion';
// @ts-ignore - Suppress likely incorrect TS error after clean install
import NumberFlow, { useCanAnimate } from '@number-flow/react';
import { Icon } from '@/components/Icon';
import { useListingContext } from '@/context/ListingContext';
import { transformQueryToApiParams } from '@/lib/api-transforms';

/**
 * Props for the Footer component
 */
export interface FooterProps {
  /** The total number of matches for the current filter settings */
  totalMatches: number;
  /** Callback for clearing all filters */
  onClearAll: () => void;
  /** Callback for closing the filter modal and applying filters */
  onClose: () => void;
}

/**
 * Footer component for the FilterModal with match count and action buttons
 */
export const Footer: React.FC<FooterProps> = ({
  totalMatches,
  onClearAll,
  onClose
}) => {
  // Check if NumberFlow can animate
  const canAnimate = useCanAnimate();

  return (
    <div className="sticky bottom-0 z-10 bg-white dark:bg-neutral-900 border-t border-gray-200 dark:border-neutral-800 drawer-footer shadow-lg">
      <div className="py-1 px-4">
        <div className="flex items-center justify-between">
          <span className="flex items-center">
            <motion.span 
              className="flex items-center"
              layout={canAnimate}
              transition={{ layout: { duration: 0.6, type: 'spring', bounce: 0 } }}
            >
              <span className="footer-count-container">
                <NumberFlow 
                  value={totalMatches} 
                  format={{ useGrouping: true }}
                  className="footer-matches-count"
                  transformTiming={{ duration: 1000, easing: 'cubic-bezier(0.16, 1, 0.3, 1)' }}
                  layout={canAnimate}
                  layoutRoot={canAnimate}
                /> 
                <span className="ml-1 text-sm text-gray-600 dark:text-neutral-400 matches-label">
                  {totalMatches === 1 ? 'match' : 'matches'}
                </span>
              </span>
            </motion.span>
          </span>
          
          <div className="flex items-center gap-3">
            <button
              onClick={onClearAll}
              className="px-3 py-2 bg-gray-100 hover:bg-gray-200 dark:bg-neutral-800 dark:hover:bg-neutral-700 text-gray-700 dark:text-neutral-200 text-sm font-medium rounded-md transition-colors"
            >
              <Icon name="X" className="mr-1 h-4 w-4" /> Clear All
            </button>
            <button
              onClick={onClose}
              className="px-4 py-2 bg-blue-800 hover:bg-blue-900 dark:bg-blue-800 dark:hover:bg-blue-900 text-white text-sm font-medium rounded-md transition-colors"
            >
              View Results
            </button>
          </div>
        </div>
      </div>

      {/* Footer styles for number flow animations */}
      <style>{`
        /* NumberFlow custom styling */
        .footer-matches-count {
          --number-flow-char-height: 1.5em;
          --number-flow-mask-height: 0.25em;
          font-size: 2rem !important;
          font-weight: 700 !important;
          color: #1e3a8a !important; /* blue-900 */
          letter-spacing: -0.02em;
          text-shadow: 0 1px 2px rgba(0,0,0,0.1);
          line-height: 1;
        }
        
        .dark .footer-matches-count {
          color: #3b82f6 !important; /* blue-500 */
          text-shadow: 0 1px 3px rgba(0,0,0,0.2);
        }
        
        .footer-count-container {
          display: flex;
          align-items: center;
        }
        
        .matches-label {
          font-size: 0.875rem;
          margin-left: 0.5rem;
          position: relative;
          top: 0;
          line-height: 1;
          padding-bottom: 2px;
        }
      `}</style>
    </div>
  );
};

export default Footer;
