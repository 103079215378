import * as React from 'react';
import { useMemo, useState, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { PillGroup } from '@/components/common/pills';
import { SearchToggle } from '@/components/common/search-input';
import { RangeSlider } from '@/components/common/range-slider';
import type { Option } from '@/types/api';

/**
 * Extended option types for model filtering
 */
type ModelFamilyOption = Option & { make?: string; modelFamily?: string };
type ModelOption = Option & { make?: string; modelFamily?: string };

/**
 * Props for the MakeModelYear component
 */
export interface MakeModelYearProps {
  /** Currently selected makes */
  selectedMakes: Option[];
  /** Currently selected model families */
  selectedModelFamilies: ModelFamilyOption[];
  /** Currently selected models */
  selectedModels: ModelOption[];
  /** Current year range */
  yearRange: [number, number];
  /** All available filter options */
  availableFilters: {
    makes: Option[];
    modelFamilies: ModelFamilyOption[];
    models: ModelOption[];
    years: { min: number; max: number };
  };
  /** Filtered makes based on search */
  filteredMakes: Option[];
  /** Filtered model families based on search */
  filteredModelFamilies: ModelFamilyOption[];
  /** Filtered models based on search */
  filteredModels: ModelOption[];
  /** Enhanced selected model families (with make property set) */
  enhancedSelectedModelFamilies: ModelFamilyOption[];
  /** Enhanced selected models (with make and modelFamily properties set) */
  enhancedSelectedModels: ModelOption[];
  
  /** Callback for make changes */
  onMakeChange: (selected: Option | Option[] | null) => void;
  /** Callback for model family changes */
  onModelFamilyChange: (selected: ModelFamilyOption | ModelFamilyOption[] | null) => void;
  /** Callback for model changes */
  onModelChange: (selected: ModelOption | ModelOption[] | null) => void;
  /** Callback for year range changes */
  onYearChange: (values: [number, number]) => void;
  
  // Search state and handlers
  /** Make search query */
  makeSearch: string;
  /** Model family search query */
  modelFamilySearch: string;
  /** Model search query */
  modelSearch: string;
  /** Setter for make search query */
  setMakeSearch: (value: string) => void;
  /** Setter for model family search query */
  setModelFamilySearch: (value: string) => void;
  /** Setter for model search query */
  setModelSearch: (value: string) => void;
  
  // Toggle state for search inputs
  /** Whether make search is visible */
  showMakeSearch: boolean;
  /** Whether model family search is visible */
  showModelFamilySearch: boolean;
  /** Whether model search is visible */
  showModelSearch: boolean;
  /** Setter for make search visibility */
  setShowMakeSearch: (visible: boolean) => void;
  /** Setter for model family search visibility */
  setShowModelFamilySearch: (visible: boolean) => void;
  /** Setter for model search visibility */
  setShowModelSearch: (visible: boolean) => void;
  
  // Refs for search inputs
  /** Reference to make search input */
  makeSearchRef: React.RefObject<HTMLInputElement>;
  /** Reference to model family search input */
  modelFamilySearchRef: React.RefObject<HTMLInputElement>;
  /** Reference to model search input */
  modelSearchRef: React.RefObject<HTMLInputElement>;
}

/**
 * Component for filtering by make, model, and year
 */
export const MakeModelYear: React.FC<MakeModelYearProps> = ({
  selectedMakes,
  selectedModelFamilies,
  selectedModels,
  yearRange,
  availableFilters,
  filteredMakes,
  filteredModelFamilies,
  filteredModels,
  enhancedSelectedModelFamilies,
  enhancedSelectedModels,
  onMakeChange,
  onModelFamilyChange,
  onModelChange,
  onYearChange,
  makeSearch,
  modelFamilySearch,
  modelSearch,
  setMakeSearch,
  setModelFamilySearch,
  setModelSearch,
  showMakeSearch,
  showModelFamilySearch,
  showModelSearch,
  setShowMakeSearch,
  setShowModelFamilySearch,
  setShowModelSearch,
  makeSearchRef,
  modelFamilySearchRef,
  modelSearchRef
}) => {
  // Use local state for smoother slider interaction
  const [localYearRange, setLocalYearRange] = React.useState<[number, number]>(yearRange);
  
  // Keep local state in sync with props
  React.useEffect(() => {
    setLocalYearRange(yearRange);
  }, [yearRange]);
  return (
    <div className="space-y-6">
      <div>
        <div className="mb-2">
          <SearchToggle
            value={makeSearch}
            onChange={setMakeSearch}
            isVisible={showMakeSearch}
            onToggle={() => setShowMakeSearch(!showMakeSearch)}
            title="Make"
            placeholder="Search makes..."
            inputRef={makeSearchRef}
            rightElement={selectedMakes.length > 0 ? (
              <button
                onClick={() => onMakeChange(null)}
                className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
              >
                Clear All
              </button>
            ) : null}
          />
        </div>
        <PillGroup
          options={filteredMakes}
          selectedValues={selectedMakes.map(m => String(m.value))}
          onChange={onMakeChange}
          maxDisplay={10}
          emptyMessage="No makes available based on your filters"
          isModelSection={false}
          isMakeSection={true}
        />
      </div>

      <div className={`${selectedMakes.length === 0 ? 'opacity-60' : ''}`}>
        <div className="mb-2">
          <SearchToggle
            value={modelFamilySearch}
            onChange={setModelFamilySearch}
            isVisible={showModelFamilySearch}
            onToggle={() => selectedMakes.length > 0 && setShowModelFamilySearch(!showModelFamilySearch)}
            title="Model Family"
            placeholder="Search model families..."
            disabled={selectedMakes.length === 0}
            inputRef={modelFamilySearchRef}
            rightElement={selectedModelFamilies.length > 0 ? (
              <button
                onClick={() => onModelFamilyChange(null)}
                className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
              >
                Clear All
              </button>
            ) : null}
          />
        </div>
        {selectedMakes.length === 0 ? (
          <p className="text-sm text-gray-500 dark:text-neutral-400 py-2">Please select at least one make first</p>
        ) : (
          <>
            {selectedMakes.length === 1 ? (
              // Single make selected - flat list display
              <PillGroup
                options={filteredModelFamilies}
                selectedValues={enhancedSelectedModelFamilies.map(m => String(m.value))}
                onChange={onModelFamilyChange}
                maxDisplay={20}
                emptyMessage="No model families available based on your filters"
                isModelSection={true}
              />
            ) : (
              // Multiple makes selected - group by make
              <div className="space-y-4">
                {selectedMakes.map(make => {
                  const makeName = make.value?.toString().toUpperCase() || '';
                  
                  // Filter model families for current make, showing only those with counts
                  const makeModelFamilies = filteredModelFamilies.filter(
                    mf => mf.make?.toUpperCase() === makeName
                  );
                  
                  if (makeModelFamilies.length === 0) return null;
                  
                  // Get model families selected for this specific make
                  const makeSelectedValues = enhancedSelectedModelFamilies
                    .filter(mf => mf.make?.toUpperCase() === makeName)
                    .map(m => String(m.value));
                  
                  // Handler for model family selection within this make's section
                  const handleMakeModelFamilyChange = (selected: ModelFamilyOption[]) => {
                    // Remove existing selections for this make
                    const otherMakesModelFamilies = enhancedSelectedModelFamilies.filter(
                      mf => mf.make?.toUpperCase() !== makeName
                    );
                    
                    // Ensure the new selections have the make property set
                    const selectedWithMake = selected.map(option => ({
                      ...option,
                      make: makeName
                    }));
                    
                    // Combine with existing selections from other makes
                    onModelFamilyChange([...otherMakesModelFamilies, ...selectedWithMake]);
                  };
                  
                  return (
                    <div key={`make-${make.value}`} className="pt-2 first:pt-0">
                      <h4 className="text-xs uppercase font-semibold text-gray-700 dark:text-neutral-300 mb-2 border-b border-gray-200 dark:border-neutral-700 pb-1">
                        {make.label}
                      </h4>
                      <PillGroup
                        options={makeModelFamilies}
                        selectedValues={makeSelectedValues}
                        onChange={handleMakeModelFamilyChange}
                        maxDisplay={20}
                        emptyMessage=""
                        isModelSection={true}
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>

      <div className={`${enhancedSelectedModelFamilies.length === 0 ? 'opacity-60' : ''}`}>
        <div className="mb-2">
          <SearchToggle
            value={modelSearch}
            onChange={setModelSearch}
            isVisible={showModelSearch}
            onToggle={() => enhancedSelectedModelFamilies.length > 0 && setShowModelSearch(!showModelSearch)}
            title="Model"
            placeholder="Search models..."
            disabled={enhancedSelectedModelFamilies.length === 0}
            inputRef={modelSearchRef}
            rightElement={selectedModels.length > 0 ? (
              <button
                onClick={() => onModelChange(null)}
                className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
              >
                Clear All
              </button>
            ) : null}
          />
        </div>
        {enhancedSelectedModelFamilies.length === 0 ? (
          <p className="text-sm text-gray-500 dark:text-neutral-400 py-2">Please select at least one model family first</p>
        ) : (
          <>
            {selectedMakes.length === 1 && enhancedSelectedModelFamilies.length === 1 ? (
              // Single make and model family selected - flat list display
              <PillGroup
                options={filteredModels}
                selectedValues={enhancedSelectedModels.map(m => String(m.value))}
                onChange={onModelChange}
                maxDisplay={18}
                emptyMessage="No models available based on your filters"
                isModelSection={true}
              />
            ) : (
              // Multiple makes or model families - group by model family
              <div className="space-y-4">
                {enhancedSelectedModelFamilies.map(modelFamily => {
                  const familyValue = String(modelFamily.value).toUpperCase();
                  const makeValue = modelFamily.make?.toUpperCase() || '';
                  
                  // Get make label for this model family
                  const makeForFamily = selectedMakes.find(make => 
                    make.value?.toString().toUpperCase() === makeValue
                  );
                  const makeLabel = makeForFamily?.label || '';
                  
                  // Filter models for current model family
                  let familyModels = filteredModels.filter(
                    model => model.modelFamily?.toUpperCase() === familyValue
                  );
                  
                  // If there are no models that match the filter but we have models selected for this family,
                  // we should still show them in the list
                  const selectedModelsForFamily = enhancedSelectedModels.filter(
                    model => model.modelFamily?.toUpperCase() === familyValue
                  );
                  
                  // Find existing selected models that aren't in the filtered models
                  const selectedButFilteredOut = selectedModelsForFamily.filter(
                    selected => !familyModels.some(model => 
                      String(model.value).toUpperCase() === String(selected.value).toUpperCase()
                    )
                  );
                  
                  // Add these back to our family models list
                  if (selectedButFilteredOut.length > 0) {
                    // Add selected models that were filtered out back to the list
                    // Mark them as having 0 count to indicate they're not in the current result set
                    const selectedOptions = selectedButFilteredOut.map(model => ({
                      ...model,
                      count: 0
                    }));
                    
                    familyModels = [...familyModels, ...selectedOptions];
                  }
                  
                  // Get models selected for this specific model family
                  const familySelectedValues = enhancedSelectedModels
                    .filter(m => m.modelFamily?.toUpperCase() === familyValue)
                    .map(m => String(m.value));
                  
                  // Handler for model selection within this model family
                  const handleFamilyModelChange = (selected: ModelOption[]) => {
                    // Remove existing selections for this model family
                    const otherFamiliesModels = enhancedSelectedModels.filter(
                      m => m.modelFamily?.toUpperCase() !== familyValue
                    );
                    
                    // Ensure the new selections have the model family and make properties set
                    const selectedWithFamily = selected.map(option => ({
                      ...option,
                      modelFamily: familyValue,
                      make: makeValue
                    }));
                    
                    // Combine with existing selections from other model families
                    onModelChange([...otherFamiliesModels, ...selectedWithFamily]);
                  };
                  
                  return (
                    <div key={`family-${modelFamily.value}`} className="pt-2 first:pt-0">
                      <h4 className="text-xs uppercase font-semibold text-gray-700 dark:text-neutral-300 mb-2 border-b border-gray-200 dark:border-neutral-700 pb-1">
                        {selectedMakes.length > 1 ? `${makeLabel} - ${modelFamily.label}` : modelFamily.label}
                      </h4>
                      {familyModels.length === 0 ? (
                        <p className="text-sm text-gray-500 dark:text-neutral-400 p-2">No models available for this selection</p>
                      ) : (
                        <PillGroup
                          options={familyModels}
                          selectedValues={familySelectedValues}
                          onChange={handleFamilyModelChange}
                          maxDisplay={8}
                          emptyMessage=""
                          isModelSection={true}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </>
        )}
      </div>

      <div>
        <RangeSlider
          value={localYearRange}
          onChange={(values) => {
            setLocalYearRange(values as [number, number]);
            onYearChange(values as [number, number]);
          }}
          min={availableFilters.years?.min || 1950}
          max={availableFilters.years?.max || new Date().getFullYear() + 1}
          step={1}
          label="Year"
          formatMin={(val) => String(val)}
          formatMax={(val) => String(val)}
        />
      </div>
    </div>
  );
};

export default MakeModelYear;
