import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Icon } from '@/components/Icon';
import { api } from '@/api/client.js';
import { SearchHeader } from '@/components/search/SearchHeader';
import { SearchControls } from '@/components/search/SearchControls';
import { SearchResults } from '@/components/search/SearchResults';
import { SearchPagination } from '@/components/search/SearchPagination';
import { SearchDetailModal } from '@/components/search/SearchDetailModal';
import { SearchMetricsConfig } from '@/components/search/SearchMetricsConfig';
import type { ProductListing, CardMetric, MetricOption, Option, EquipmentCategory } from '@/types/api.js';
import { equipmentCategories } from '@/types/api.js';
import { FilterPillDisplay } from '@/components/search/FilterPillDisplay';
import { ListingCard } from '@/components/ListingCard.js';
import { FilterUpdateIndicator } from '@/components/FilterUpdateIndicator.js';
import { Suspense } from 'react';
import { useListingContext } from '@/context/ListingContext';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { LoadingIndicator } from '@/components/LoadingIndicator';
import { useListingQueryState } from '@/hooks/useListingQueryState';
import type { QueryState, SortOption } from '@/types/queryState';
import { isCardMetric } from '@/types/queryState';
import { useQueryStates, parseAsArrayOf, parseAsString, parseAsBoolean, parseAsJson } from 'nuqs';
import { RANGE_CONFIGS } from '@/shared/range-config';
import { FavoritesManager } from '@/utils/favorites';
import { FavoriteButton } from '@/components/FavoriteButton';
import { FilterModal } from '@/components/features/filter/FilterModal';
import { SelectDropdown } from '@/components/SelectDropdown';
import { motion, AnimatePresence } from 'framer-motion';
import { FavoritesCounter } from '@/components/FavoritesCounter';
import { DarkModeToggle } from '@/components/DarkModeToggle';
import { cn, buttonStyles } from '@/lib/utils';
import { ListingDetailModal } from '@/components/ListingDetailModal.js';
import { ListingImagesProvider, useListingImages } from '@/contexts/ListingImagesContext';
import { ImageModal } from '@/components/ImageModal.js';
import { useColumnCount } from '@/hooks/useColumnCount';
import { DEFAULT_QUERY_STATE } from '@/types/queryState';
import { validateSort, validateColumnCount } from '@/lib/parsers';

import { animations } from '@/lib/animation-config';
import { useFilterHandlers } from '@/hooks/useFilterHandlers';

import './PropellerAnimation.css';

const DEFAULT_METRICS: CardMetric[] = [
  'registration',
  'year',
  'totalTime',
  'engineTime',
  'propTime',
  'flightRules'
];

const availableMetrics: MetricOption[] = [
  // Basic Info metrics
  { key: 'registration', label: 'Tail #', group: 'basic' },
  { key: 'year', label: 'Year', group: 'basic' },
  { key: 'totalTime', label: 'Total Time', group: 'basic' },
  { key: 'engineTime', label: 'Engine Time', group: 'basic' },
  { key: 'propTime', label: 'Prop Time', group: 'basic' },
  { key: 'flightRules', label: 'Flight Rules', group: 'basic' },
  { key: 'make', label: 'Make', group: 'basic' },
  { key: 'model', label: 'Model', group: 'basic' },
  { key: 'serialNumber', label: 'Serial #', group: 'basic' },
  { key: 'usefulLoad', label: 'Useful Load', group: 'basic' },
  // Equipment metrics
  ...Object.entries(equipmentCategories).map(([key, label]) => ({
    key: `equipment.${key}` as CardMetric,
    label,
    group: 'equipment' as const
  }))
];

// Update the interface for API response to include modelFamily
interface ExtendedListingsApiResponse {
  listings: ProductListing[];
  total: number;
  totalPages: number;
  availableFilters: {
    makes: Array<Option>;
    model: Array<Option & { make: string; modelFamily?: string }>;
    modelFamily?: Array<Option & { make: string }>;
    equipment: Record<string, Option[]>;
    prices: Array<{ min: number; max: number }>;
    conditions?: Array<Option>;
    saleStatuses?: Array<Option>;
    intendedUses?: Array<Option>;
    categories?: Array<Option>;
  };
}

// Define sortOptions for mobile layout
const sortOptions = [
  { value: 'relevance', label: 'Most Relevant' },
  { value: 'price-asc', label: 'Price: Low to High' },
  { value: 'price-desc', label: 'Price: High to Low' },
  { value: 'year-asc', label: 'Year: Oldest' },
  { value: 'year-desc', label: 'Year: Newest' },
  { value: 'date-listed', label: 'Recently Listed' },
  { value: 'updated-desc', label: 'Recently Updated' },
  { value: 'updated-asc', label: 'Oldest Updates' },
];

// Helper function to format sort options
const formatSortOptionLabel = (option: { value: string; label: string }) => (
  <div className="flex items-center py-2 px-3">
    <span>{option.label}</span>
  </div>
);

// Function to convert sale status string to Option objects
const getSaleStatusOptions = (saleStatusString: string, availableOptions: Option[]): Option[] => {
  if (!saleStatusString) return [];
  
  const statusValues = saleStatusString.split(',');
  return statusValues
    .map(value => {
      const option = availableOptions.find(opt => opt.value === value);
      return option || null;
    })
    .filter(Boolean) as Option[];
};

export const AircraftSearch = () => {
  const { queryState, setQueryState, isFiltered, clearFilters } = useListingQueryState();
  const [columnCount, setColumnCount] = useColumnCount();
  const [searchParams, setSearchParams] = useSearchParams();
  
  // Simple scrollToTop function
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);
  
  // UI State
  const [availableFilters, setAvailableFilters] = useState<{
    makes: Option[];
    modelFamilies: Array<Option & { make: string }>;
    models: Array<Option & { make: string, modelFamily?: string }>;
    years: { min: number; max: number };
    conditions?: Option[];
    saleStatuses?: Option[];
    intendedUses?: Option[];
    categories?: Option[];
  }>({
    makes: [],
    modelFamilies: [],
    models: [],
    years: { min: 0, max: 0 },
    conditions: [],
    saleStatuses: [],
    intendedUses: [],
    categories: []
  });

  // Filter modal state
  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  
  // Metrics configuration state
  const [isMetricsConfigOpen, setIsMetricsConfigOpen] = useState(false);
  
  // Component State
  const [isInitialLoading, setIsInitialLoading] = useState(true); // For initial page load only
  const [isLoading, setIsLoading] = useState(true); // For all data fetches
  const [error, setError] = useState<string | null>(null);
  const [listings, setListings] = useState<ProductListing[]>([]);
  const { updateMetadata, metadata } = useListingContext();
  const abortControllerRef = useRef<AbortController | null>(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isLoadingComplete, setIsLoadingComplete] = useState(false); // State to track complete loading
  const [showSpinner, setShowSpinner] = useState(true); // Explicit state for spinner visibility - initially true
  
  // Flag to track if we should skip data fetching for the current state update
  const skipFetchRef = useRef(false);
  // Timestamp to track when loading started
  const loadStartTimeRef = useRef<number>(0);

  // Convert UI Preferences to URL state
  const [uiState, setUiState] = useQueryStates(
    {
      visibleMetrics: parseAsArrayOf(parseAsString).withDefault(DEFAULT_METRICS),
      showMetricsConfig: parseAsString.withDefault('false'),
      showFilter: parseAsBoolean.withDefault(false),
      sort: parseAsJson(validateSort).withDefault(DEFAULT_QUERY_STATE.sort),
      columnCount: parseAsJson(validateColumnCount).withDefault(DEFAULT_QUERY_STATE.columnCount),
      showSold: parseAsBoolean.withDefault(false)
    },
    {
      history: 'replace',
      shallow: true
    }
  );

  // State for favorites count
  const [favoritesCount, setFavoritesCount] = useState(0);
  
  // Filter change handler
  const handleFilterChange = (newFilters: Partial<QueryState>) => {
    console.log('New filters:', newFilters);
    
    // Validate sort value if present
    const sort = newFilters.sort;
    if (sort && !['relevance', 'price-asc', 'price-desc', 'year-asc', 'year-desc', 'date-listed', 'updated-desc', 'updated-asc'].includes(sort)) {
      console.warn(`Invalid sort value: ${sort}, defaulting to 'relevance'`);
      newFilters.sort = 'relevance' as SortOption;
    }

    // Create a type-safe copy of the filters
    const safeFilters = { ...newFilters };
    
    setQueryState(prev => {
      // Create a new state object with the updated values
      const newState = {
        ...prev,
        ...safeFilters,
      };
      
      // Reset page to 1 for filter changes if not explicitly changing page
      if (!('page' in safeFilters)) {
        newState.page = 1;
      }
      
      return newState;
    });
  };
  
  // Use our centralized filter handlers with the adjusted QueryState type
  const filterHandlers = useFilterHandlers(
    queryState as unknown as QueryState, 
    handleFilterChange, 
    availableFilters
  );
  
  // Handle search input directly
  const handleSearch = (searchTerm: string) => {
    console.log('Search term:', searchTerm);
    handleFilterChange({ search: searchTerm });
  };

  // Update favorites count when component mounts and when a favorite is added/removed
  useEffect(() => {
    const updateFavoritesCount = () => {
      setFavoritesCount(FavoritesManager.getFavoritesCount());
    };
    
    // Update count initially
    updateFavoritesCount();
    
    // Listen for storage events to update count when favorites change
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === 'product-favorites') {
        updateFavoritesCount();
      }
    };
    
    window.addEventListener('storage', handleStorageChange);
    
    // Custom event for when favorites change within the same window
    const handleFavoritesChange = () => {
      updateFavoritesCount();
    };
    
    window.addEventListener('favoritesChanged', handleFavoritesChange);
    
    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('favoritesChanged', handleFavoritesChange);
    };
  }, []);

  // Handlers for UI state
  const handleVisibleMetricsChange = (metrics: string[]) => {
    const validMetrics = metrics.filter(isCardMetric);
    setUiState(prev => ({ ...prev, visibleMetrics: validMetrics }));
  };

  const handleMetricsConfigToggle = (show: boolean) => {
    setUiState(prev => ({ ...prev, showMetricsConfig: show.toString() }));
  };

  const handleSortChange = (sort: SortOption) => {
    handleFilterChange({ sort });
  };

  const handlePageChange = (newPage: number) => {
    if (newPage < 1 || newPage > metadata.totalPages) return;
    
    setQueryState(prev => ({
      ...prev,
      page: newPage
    }));
    scrollToTop();
  };

  const handleImageClick = (listing: ProductListing, index: number) => {
      // Use the ListingCard's built-in openDetailModal function directly
    const id = listing.aircraftId || listing._id;
    console.log('Opening detail modal for listing:', id);
    
    // Create a custom event to open the detail modal
    const event = new CustomEvent('openDetailModal', {
      detail: {
        id,
        sourceRect: null
      }
    });
    
    // Dispatch the event
    window.dispatchEvent(event);
  };

  // Create options for filter dropdowns
  const makeOptions = useMemo(() => {
    return queryState.makes.map(value => {
      const option = availableFilters.makes.find(make => make.value === value);
      return {
        value,
        label: option?.label || value,
        count: option?.count || 0,
        zeroCount: option?.zeroCount || 0
      };
    });
  }, [queryState.makes, availableFilters.makes]);

  const modelFamilyOptions = useMemo(() => {
    return queryState.modelFamilies.map(value => {
      const option = availableFilters.modelFamilies.find(modelFamily => modelFamily.value === value);
      return {
        value,
        label: option?.label || value,
        count: option?.count || 0,
        zeroCount: option?.zeroCount || 0
      };
    });
  }, [queryState.modelFamilies, availableFilters.modelFamilies]);

  const modelOptions = useMemo(() => {
    return queryState.models.map(value => {
      const option = availableFilters.models.find(model => model.value === value);
      return {
        value,
        label: option?.label || value,
        count: option?.count || 0,
        zeroCount: option?.zeroCount || 0
      };
    });
  }, [queryState.models, availableFilters.models]);

  // Create options for the new filter dropdowns
  const conditionOption = useMemo(() => {
    if (!queryState.condition) return null;
    
    return {
      value: queryState.condition,
      label: queryState.condition,
      count: 0,
      zeroCount: 0
    };
  }, [queryState.condition]);

  const saleStatusOption = useMemo(() => {
    if (!queryState.saleStatus) return null;
    
    return {
      value: queryState.saleStatus,
      label: queryState.saleStatus,
      count: 0,
      zeroCount: 0
    };
  }, [queryState.saleStatus]);

  const intendedUseOptions = useMemo(() => {
    return queryState.intendedUse.map(value => ({
      value,
      label: value,
      count: 0,
      zeroCount: 0
    }));
  }, [queryState.intendedUse]);

  const categoryOption = useMemo(() => {
    if (!queryState.category) return null;
    
    return {
      value: queryState.category,
      label: queryState.category,
      count: 0,
      zeroCount: 0
    };
  }, [queryState.category]);

  // Add a simple effect to load images for all listings at once
  const { loadImagesForListings } = useListingImages();
  useEffect(() => {
    if (listings.length > 0 && !isInitialLoading && !isUpdating) {
      // Simple direct call - no throttling or complex logic needed
      const listingIds = listings.map(listing => listing.aircraftId || listing._id);
      loadImagesForListings(listingIds);
    }
  }, [listings, loadImagesForListings, isInitialLoading, isUpdating]);

  // Add a useEffect to initialize properly and handle initial page load
  useEffect(() => {
    // Fetch listings will handle initial loading too
    // Set initial loading state for spinner
    setShowSpinner(true);
    setIsLoadingComplete(false);
    
    // After component mounts, we'll consider any future updates "non-initial"
    return () => {
      setIsInitialLoading(false);
    };
  }, []);

  // Fetch listings whenever queryState changes
  useEffect(() => {
    // If we should skip fetching for this update, reset the flag and return
    if (skipFetchRef.current) {
      skipFetchRef.current = false;
      return;
    }
    
    const fetchListings = async () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
      abortControllerRef.current = new AbortController();
      
      // Record the start time of the loading operation
      loadStartTimeRef.current = Date.now();

      // Set updating state immediately
      setIsUpdating(true);
      setIsLoadingComplete(false); // Reset loading complete status
      setShowSpinner(true); // Always show spinner

      try {
        const response = await api.listings.getAll(queryState as unknown as Partial<QueryState>, {
          signal: abortControllerRef.current.signal
        });
        
        // Handle the response safely
        if (response.error) {
          setError(response.error);
        } else if (response.data) {
          // Use the data without type assertion
          const data = response.data;
          
          // Only update listings if data has actually changed
          // This prevents unnecessary rerenders and animation flickers
          if (JSON.stringify(data.listings.map(l => l._id)) !== JSON.stringify(listings.map(l => l._id))) {
            setListings(data.listings);
          }
          
          updateMetadata({
            total: data.total,
            totalPages: Math.ceil(data.total / queryState.itemsPerPage)
          });

          if (data.availableFilters) {
            const { makes = [], model = [] } = data.availableFilters;
            // Safely access modelFamily with a fallback to empty array
            // @ts-ignore - modelFamily might not exist in the API response type
            const modelFamily = data.availableFilters.modelFamily || [];
            
            // Type assertion for the API response data
            const apiFilters = data.availableFilters as unknown as ExtendedListingsApiResponse['availableFilters'];
            
            setAvailableFilters({
              makes: makes.map(make => ({
                value: make.value,
                label: make.label,
                count: make.count || 0,
                zeroCount: make.zeroCount || 0
              })),
              modelFamilies: modelFamily.map(family => ({
                value: family.value,
                label: family.label,
                count: family.count || 0,
                zeroCount: family.zeroCount || 0,
                make: family.make
              })),
              models: model.map(model => {
                // Safely access modelFamily property with a fallback
                // @ts-ignore - modelFamily might not exist in the model type
                const modelFamilyValue = model.modelFamily || undefined;
                
                return {
                  value: model.value,
                  label: model.label,
                  count: model.count || 0,
                  zeroCount: model.zeroCount || 0,
                  make: model.make,
                  modelFamily: modelFamilyValue
                };
              }),
              years: { min: RANGE_CONFIGS.yearRange.min, max: RANGE_CONFIGS.yearRange.max },
              
              // Add the new filter options from the API response
              conditions: apiFilters.conditions?.map(item => ({
                value: item.value,
                label: item.label,
                count: item.count || 0,
                zeroCount: item.zeroCount || 0
              })) || [],
              
              saleStatuses: apiFilters.saleStatuses?.map(item => ({
                value: item.value,
                label: item.label,
                count: item.count || 0,
                zeroCount: item.zeroCount || 0
              })) || [],
              
              intendedUses: apiFilters.intendedUses?.map(item => ({
                value: item.value,
                label: item.label,
                count: item.count || 0,
                zeroCount: item.zeroCount || 0
              })) || [],
              
              categories: apiFilters.categories?.map(item => ({
                value: item.value,
                label: item.label,
                count: item.count || 0,
                zeroCount: item.zeroCount || 0
              })) || []
            });
          }

          setError(null);
        }
      } catch (err) {
        if (err instanceof Error && err.name === 'AbortError') return;
        setError(err instanceof Error ? err.message : 'Failed to fetch listings');
      } finally {
        // Calculate how long the operation took
        const elapsed = Date.now() - loadStartTimeRef.current;
        // Ensure spinner shows for at least 1000ms
        const minLoadingTime = 1000;
        const remainingTime = Math.max(0, minLoadingTime - elapsed);
        
        // Wait at least the minimum loading time before starting to hide spinner
        setTimeout(() => {
          setIsLoading(false);
          setIsInitialLoading(false);
          
          // Keep the updating indicator visible a bit longer
          setTimeout(() => {
            setShowSpinner(false); // Hide spinner
            setIsUpdating(false);
            abortControllerRef.current = null;
            
            // Set loading complete after spinner fade-out
            setTimeout(() => {
              setIsLoadingComplete(true);
            }, 300);
          }, 500); 
        }, remainingTime);
      }
    };

    // Debounce the fetch if it's a filter change
    const timeoutId = setTimeout(fetchListings, isUpdating ? 300 : 0);
    return () => clearTimeout(timeoutId);
  }, [queryState, updateMetadata]);

  return (
    <div className="min-h-screen bg-gray-50 dark:bg-neutral-900">
      <div className="mx-auto max-w-[1600px] px-4 sm:px-6 lg:px-8 w-full flex-1">
        {/* Header with Title, Search, Filters and Favorites - Redesigned */}
        <SearchHeader 
          favoritesCount={favoritesCount}
          onOpenFilterModal={() => setIsFilterModalOpen(true)}
        />

        <div className="relative z-[50]">          
          {/* Filter Pills Row - Desktop */}
          <div>
            <FilterPillDisplay
              onFilterChange={handleFilterChange}
              availableFilters={availableFilters}
              currentFilters={queryState as unknown as QueryState}
              onClearAll={clearFilters}
            />
          </div>
        </div>

        {/* Main Content */}
        <ErrorBoundary>
          <Suspense fallback={<LoadingIndicator />}>
            <div className="flex flex-col">
              {error ? (
                <div className="text-red-500">Error: {error}</div>
              ) : (
                <>
                  <SearchResults 
                    listings={listings}
                    isLoading={isLoading}
                    isLoadingComplete={isLoadingComplete}
                    showSpinner={showSpinner}
                    columnCount={columnCount}
                    visibleMetrics={uiState.visibleMetrics}
                    currentDetailId={null} // We're now using SearchDetailModal to manage this
                    recentlyClosedId={null} // We're now using SearchDetailModal to manage this
                    onImageClick={handleImageClick}
                  />

                  {/* Pagination */}
                  <SearchPagination
                    currentPage={queryState.page}
                    totalPages={metadata.totalPages}
                    totalItems={metadata.total}
                    itemsPerPage={queryState.itemsPerPage}
                    visibleItems={listings.length}
                    onPageChange={handlePageChange}
                  />
                </>
              )}
            </div>
          </Suspense>
        </ErrorBoundary>

        {/* Detail Modal */}
        <SearchDetailModal />
        
        {/* Metrics Configuration Modal */}
        <SearchMetricsConfig 
          isVisible={uiState.showMetricsConfig === 'true'}
          availableMetrics={availableMetrics}
          visibleMetrics={uiState.visibleMetrics as CardMetric[]}
          onVisibleMetricsChange={handleVisibleMetricsChange}
          onClose={() => handleMetricsConfigToggle(false)}
          defaultMetrics={DEFAULT_METRICS}
        />

        {/* Filter Modal */}
        <FilterModal
          isOpen={isFilterModalOpen}
          onClose={() => setIsFilterModalOpen(false)}
          selectedMakes={filterHandlers.selectedMakes}
          selectedModelFamilies={filterHandlers.selectedModelFamilies}
          selectedModels={filterHandlers.selectedModels}
          ranges={{
            price: queryState.priceRange,
            year: queryState.yearRange,
            engineTime: queryState.engineTimeRange,
            usefulLoad: queryState.usefulLoadRange
          }}
          availableFilters={availableFilters}
          onMakeChange={filterHandlers.handleMakeChange}
          onModelFamilyChange={filterHandlers.handleModelFamilyChange}
          onModelChange={filterHandlers.handleModelChange}
          onRangeChange={filterHandlers.handleRangeChange}
          showZeroPrice={queryState.showZeroPrice}
          onShowZeroPriceChange={filterHandlers.handleShowZeroPriceChange}
          totalMatches={metadata.total}
          onClearAll={clearFilters}
          onFilterChange={handleFilterChange}
          equipment={queryState.equipment as Record<EquipmentCategory, boolean>}
          onEquipmentChange={filterHandlers.handleEquipmentChange}
          selectedCondition={filterHandlers.selectedCondition}
          selectedSaleStatuses={filterHandlers.selectedSaleStatuses}
          selectedIntendedUses={filterHandlers.selectedIntendedUses}
          selectedCategory={filterHandlers.selectedCategory}
          onConditionChange={filterHandlers.handleConditionChange}
          onSaleStatusChange={filterHandlers.handleSaleStatusChange}
          onIntendedUseChange={filterHandlers.handleIntendedUseChange}
          onCategoryChange={filterHandlers.handleCategoryChange}
          sort={queryState.sort}
          onSortChange={filterHandlers.handleSortChange}
          columnCount={columnCount}
          onLayoutChange={setColumnCount}
          onCustomizeClick={() => {
            setIsFilterModalOpen(false);
            handleMetricsConfigToggle(true);
          }}
          showSold={queryState.showSold}
          onShowSoldChange={filterHandlers.handleShowSoldChange}
        />
      </div>
    </div>
  );
};