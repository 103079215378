import { cn, buttonStyles } from '../../lib/utils';

interface SearchPaginationProps {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  visibleItems: number;
  onPageChange: (page: number) => void;
  className?: string;
}

/**
 * SearchPagination component for displaying pagination controls
 * Used to navigate between pages of search results
 */
export const SearchPagination = ({
  currentPage,
  totalPages,
  totalItems,
  itemsPerPage,
  visibleItems,
  onPageChange,
  className
}: SearchPaginationProps) => {
  // Helper function to calculate listing range for pagination display
  const getListingRange = () => {
    const start = ((currentPage - 1) * itemsPerPage) + 1;
    const end = Math.min(start + visibleItems - 1, totalItems);
    return { start, end };
  };

  return (
    <div className={`fixed bottom-0 left-0 right-0 bg-white dark:bg-neutral-900 border-t border-gray-200 dark:border-gray-700 px-2 sm:px-4 py-2 z-30 ${className || ''}`}>
      <div className="container mx-auto">
        {/* Mobile Pagination Layout */}
        <div className="flex sm:hidden">
          <div className="flex justify-between items-center w-full">
            <button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={cn(
                buttonStyles.base,
                buttonStyles.rounded,
                buttonStyles.shadow,
                'flex items-center text-sm h-10 px-3 py-1',
                currentPage === 1 && 'opacity-50 cursor-not-allowed'
              )}
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-4 w-4 mr-1" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor" 
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
              </svg>
              Prev
            </button>
            
            <div className="flex flex-col items-center">
              <span className="text-sm font-medium text-gray-700 dark:text-gray-300">
                Page {currentPage} of {totalPages}
              </span>
              <span className="text-xs text-gray-500 dark:text-gray-400">
                {(() => {
                  const { start, end } = getListingRange();
                  return `${start}-${end} of ${totalItems} listings`;
                })()}
              </span>
            </div>
            
            <button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={cn(
                buttonStyles.base,
                buttonStyles.rounded,
                buttonStyles.shadow,
                'flex items-center text-sm h-10 px-3 py-1',
                currentPage === totalPages && 'opacity-50 cursor-not-allowed'
              )}
            >
              Next
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-4 w-4 ml-1" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor" 
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
        
        {/* Desktop Pagination Layout */}
        <div className="hidden sm:grid sm:grid-cols-3">
          {/* Left section - Previous button */}
          <div className="flex items-center">
            <button
              onClick={() => onPageChange(currentPage - 1)}
              disabled={currentPage === 1}
              className={cn(
                buttonStyles.base,
                buttonStyles.rounded,
                buttonStyles.shadow,
                'flex items-center text-sm h-8 px-3 py-1',
                currentPage === 1 && 'opacity-50 cursor-not-allowed'
              )}
            >
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-4 w-4 mr-1" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor" 
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M15 19l-7-7 7-7" />
              </svg>
              Previous
            </button>
          </div>

          {/* Center section - Page numbers */}
          <div className="flex justify-center items-center">
            {/* First page */}
            <button
              onClick={() => onPageChange(1)}
              className={cn(
                buttonStyles.base,
                buttonStyles.rounded,
                'h-8 w-8 mx-1 flex items-center justify-center',
                currentPage === 1
                  ? 'bg-blue-800 text-white border hover:bg-blue-800 border-blue-800'
                  : cn(
                      buttonStyles.base,
                      buttonStyles.rounded,
                      'hover:bg-gray-50 dark:hover:bg-neutral-800'
                    )
              )}
            >
              1
            </button>

            {/* Ellipsis for skipped pages at beginning */}
            {currentPage > 3 && (
              <span className="mx-1 text-gray-500 dark:text-gray-400">...</span>
            )}

            {/* Pages before current */}
            {currentPage > 2 && (
              <button
                onClick={() => onPageChange(currentPage - 1)}
                className={cn(
                  buttonStyles.base,
                  buttonStyles.rounded,
                  'h-8 w-8 mx-1 flex items-center justify-center'
                )}
              >
                {currentPage - 1}
              </button>
            )}

            {/* Current page (if not first page) */}
            {currentPage !== 1 && currentPage !== totalPages && (
              <button
                onClick={() => onPageChange(currentPage)}
                className={cn(
                  buttonStyles.base,
                  buttonStyles.rounded,
                  'h-8 w-8 mx-1 flex items-center justify-center bg-blue-800 text-white hover:bg-blue-800 border border-blue-800'
                )}
              >
                {currentPage}
              </button>
            )}

            {/* Pages after current */}
            {currentPage < totalPages - 1 && (
              <button
                onClick={() => onPageChange(currentPage + 1)}
                className={cn(
                  buttonStyles.base,
                  buttonStyles.rounded,
                  'h-8 w-8 mx-1 flex items-center justify-center'
                )}
              >
                {currentPage + 1}
              </button>
            )}

            {/* Ellipsis for skipped pages at end */}
            {currentPage < totalPages - 2 && (
              <span className="mx-1 text-gray-500 dark:text-gray-400">...</span>
            )}

            {/* Last page (if not the only page) */}
            {totalPages > 1 && (
              <button
                onClick={() => onPageChange(totalPages)}
                className={cn(
                  'h-8 w-8 mx-1 flex items-center justify-center rounded-xl',
                  currentPage === totalPages
                    ? 'bg-blue-800 text-white border border-blue-800'
                    : cn(
                        buttonStyles.base,
                        buttonStyles.rounded,
                        'hover:bg-gray-50 dark:hover:bg-neutral-800'
                      )
                )}
              >
                {totalPages}
              </button>
            )}
          </div>

          {/* Right section - Count and Next button */}
          <div className="flex items-center justify-end">
            <span className="text-sm text-gray-500 dark:text-gray-400 mr-4">
              {(() => {
                const { start, end } = getListingRange();
                return `${start}-${end} / ${totalItems}`;
              })()}
            </span>
            <button
              onClick={() => onPageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
              className={cn(
                buttonStyles.base,
                buttonStyles.rounded,
                buttonStyles.shadow,
                'flex items-center text-sm h-8 px-3 py-1',
                currentPage === totalPages && 'opacity-50 cursor-not-allowed'
              )}
            >
              Next
              <svg 
                xmlns="http://www.w3.org/2000/svg" 
                className="h-4 w-4 ml-1" 
                fill="none" 
                viewBox="0 0 24 24" 
                stroke="currentColor" 
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M9 5l7 7-7 7" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
