import * as React from 'react';
import type { ReactNode } from 'react';
import { motion } from 'framer-motion';
// @ts-ignore - Suppress likely incorrect TS error after clean install
import NumberFlow, { useCanAnimate } from '@number-flow/react';
import { useAccordionHeight } from '../../../hooks/useFilterModalHooks';

// Create motion version of NumberFlow
const MotionNumberFlow = motion(NumberFlow);

export interface AccordionProps {
  title: string;
  count: number;
  icon?: ReactNode;
  defaultOpen?: boolean;
  children: ReactNode;
  isOpen: boolean;
  onToggle: (title: string) => void;
  isLast?: boolean;
}

export const Accordion = ({ 
  title, 
  count, 
  icon, 
  children, 
  isOpen, 
  onToggle, 
  isLast = false 
}: AccordionProps) => {
  // Use the custom hook to calculate dynamic height
  const { maxHeight, accordionRef, contentRef, headerRef } = useAccordionHeight(isOpen, title);
  
  // Check if NumberFlow can animate
  const canAnimate = useCanAnimate();

  return (
    <>
      <div 
        className={`${isLast ? '' : 'border-b border-gray-300 dark:border-neutral-700'}`}
        ref={accordionRef}
      >
        <div 
          className={`w-full accordion-header`}
          ref={headerRef}
        >
        <button
          onClick={() => onToggle(title)}
          className={`w-full flex items-center justify-between transition-colors ${
            isOpen 
              ? 'bg-gray-200 dark:bg-neutral-800 border-b border-gray-300 dark:border-neutral-700' 
              : 'bg-white dark:bg-neutral-900 hover:bg-gray-100 dark:hover:bg-neutral-800'
          }`}
          style={{
            padding: 'clamp(0.75rem, 2vh, 0.8rem) clamp(1rem, 3vh, 0.95rem)'
          }}
        >
          <div className="flex items-center">
            {icon && (
              <span 
                className="mr-2 text-gray-700 dark:text-neutral-300"
                style={{
                  width: 'clamp(1.25rem, 3vh, 1.15rem)',
                  height: 'clamp(1.25rem, 3vh, 1.15rem)',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center'
                }}
              >
                {React.cloneElement(icon as React.ReactElement, {
                  size: 'clamp(1.25rem, 3vh, 1.15rem)'
                })}
              </span>
            )}
            <h3 
              className="font-medium text-gray-900 dark:text-neutral-100"
              style={{
                fontSize: 'clamp(0.875rem, 2.5vh, 1.045rem)'
              }}
            >
              {title}
              {count > 0 && (
                <motion.span 
                  className="ml-2 inline-flex items-center justify-center rounded-full text-[10px] font-medium bg-blue-200 text-blue-900 dark:bg-blue-800 dark:text-blue-50"
                  style={{
                    width: 'clamp(1rem, 2.5vh, 0.95rem)',
                    height: 'clamp(1rem, 2.5vh, 0.95rem)',
                    fontSize: 'clamp(0.625rem, 1.5vh, 0.6rem)'
                  }}
                  layout={canAnimate}
                  transition={{ layout: { duration: 0.4, type: 'spring', bounce: 0 } }}
                >
                  <MotionNumberFlow 
                    value={count} 
                    className="accordion-count-badge"
                    transformTiming={{ duration: 800, easing: 'cubic-bezier(0.16, 1, 0.3, 1)' }}
                    layout={canAnimate}
                    layoutRoot={canAnimate}
                  />
                </motion.span>
              )}
            </h3>
          </div>
          <svg 
            xmlns="http://www.w3.org/2000/svg" 
            className={`transition-transform duration-500 ease-out ${isOpen ? 'transform rotate-180' : ''}`} 
            viewBox="0 0 20 20" 
            fill="currentColor"
            style={{
              width: 'clamp(1rem, 2.5vh, 0.95rem)',
              height: 'clamp(1rem, 2.5vh, 0.95rem)'
            }}
          >
            <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
          </svg>
        </button>
      </div>
      <div 
        className={`transition-all duration-300 ease-in-out overflow-hidden ${isOpen ? 'opacity-100' : 'opacity-0 max-h-0'}`}
        style={{ maxHeight: isOpen ? maxHeight : 0 }}
      >
        <div className="relative">
          {/* Add permanent top shadow */}
          <div className="absolute top-0 left-0 right-0 h-6 pointer-events-none z-10">
            {/* Light mode shadow - hidden in dark mode */}
            <div 
              className="absolute inset-0 dark:hidden"
              style={{
                background: 'linear-gradient(to bottom, rgba(0,0,0,0.06) 0%, rgba(0,0,0,0.02) 40%, rgba(0,0,0,0) 100%)'
              }}
            ></div>
            {/* Dark mode shadow */}
            <div 
              className="absolute inset-0 hidden dark:block"
              style={{
                background: 'linear-gradient(to bottom, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.15) 40%, rgba(0,0,0,0) 100%)'
              }}
            ></div>
          </div>
          <div 
            className="bg-white dark:bg-neutral-950 overflow-y-auto"
            style={{ 
              maxHeight,
              padding: 'clamp(1.25rem, 3vh, 1.5rem) clamp(1.5rem, 4vh, 1.75rem) clamp(2rem, 4.5vh, 2.25rem)'
            }}
            ref={contentRef}
          >
            {children}
          </div>
          
          {/* Add permanent bottom shadow */}
          <div className="absolute bottom-0 left-0 right-0 h-6 pointer-events-none z-10">
            {/* Light mode shadow - hidden in dark mode */}
            <div 
              className="absolute inset-0 dark:hidden"
              style={{
                background: 'linear-gradient(to top, rgba(0,0,0,0.06) 0%, rgba(0,0,0,0.02) 40%, rgba(0,0,0,0) 100%)'
              }}
            ></div>
            {/* Dark mode shadow */}
            <div 
              className="absolute inset-0 hidden dark:block"
              style={{
                background: 'linear-gradient(to top, rgba(0,0,0,0.35) 0%, rgba(0,0,0,0.15) 40%, rgba(0,0,0,0) 100%)'
              }}
            ></div>
          </div>
        </div>
        </div>
      </div>

      {/* Accordion count badge styles */}
      <style>{`
        .accordion-count-badge {
          --number-flow-char-height: 0.625rem;
          --number-flow-mask-height: 0.1em;
        }
      `}</style>
    </>
  );
};
