import { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ListingDetailModal } from '../ListingDetailModal';

interface SearchDetailModalProps {
  isOpen?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
}

/**
 * SearchDetailModal component
 * Manages the detail view modal state, URL integration, and animations
 */
export const SearchDetailModal = ({ isOpen: externalIsOpen, onOpenChange }: SearchDetailModalProps) => {
  // Modal state
  const [isDetailModalOpen, setIsDetailModalOpen] = useState(externalIsOpen || false);
  const [currentDetailId, setCurrentDetailId] = useState<string | null>(null);
  const [lastViewedCardRect, setLastViewedCardRect] = useState<DOMRect | null>(null);
  // Track recently closed listing for animation
  const [recentlyClosedId, setRecentlyClosedId] = useState<string | null>(null);
  
  const [searchParams] = useSearchParams();

  // Define modal handlers
  const handleCloseDetailModal = useCallback((e?: CustomEvent<{ fromBackButton?: boolean }>) => {
    // Preserve the current detail ID for animation
    if (currentDetailId) {
      setRecentlyClosedId(currentDetailId);
    }
    
    // Close modal
    setIsDetailModalOpen(false);
    if (onOpenChange) onOpenChange(false);
    
    // Check if this is a result of a popstate event or direct close action
    const fromBackButton = 
      (e && e.detail && e.detail.fromBackButton) || 
      (window.history.state && window.history.state.isBackNavigation === true);
    
    if (!fromBackButton) {
      // If this is a direct close (not from back button), we want to go back to previous state
      window.history.back();
    } else {
      // If we're here from a back button press already, just update the URL without adding history
      const url = new URL(window.location.href);
      url.searchParams.delete('detailId');
      window.history.replaceState({ isBackNavigation: false }, '', url.toString());
    }
    
    // Use a stepped timing for the animation sequence
    setCurrentDetailId(null);
    
    // Clear the recently closed ID after animation completes
    setTimeout(() => {
      setRecentlyClosedId(null);
    }, 0);
  }, [currentDetailId, onOpenChange]);

  const handleOpenDetailModal = useCallback((e: CustomEvent<{ id: string, sourceRect: DOMRect | null }>) => {
    console.log('SearchDetailModal: handleOpenDetailModal called', e.detail);
    const { id, sourceRect } = e.detail;
    
    // Update modal state
    setLastViewedCardRect(sourceRect);
    setCurrentDetailId(id);
    setIsDetailModalOpen(true);
    if (onOpenChange) onOpenChange(true);
    
    // Clear any previously closed ID
    setRecentlyClosedId(null);
    
    // Update URL by pushing a new history state
    const url = new URL(window.location.href);
    url.searchParams.set('detailId', id);
    window.history.pushState({ detailId: id, isModalView: true }, '', url.toString());
  }, [onOpenChange]);

  // Handle browser back/forward
  const handlePopState = useCallback((event: PopStateEvent) => {
    const state = event.state as { detailId?: string, isModalView?: boolean, isBackNavigation?: boolean } | null;
    const detailId = new URLSearchParams(window.location.search).get('detailId');
    
    // Mark this state as resulting from a back/forward navigation
    if (state) {
      window.history.replaceState(
        { ...state, isBackNavigation: true }, 
        '', 
        window.location.href
      );
    }
    
    if (detailId || (state && state.detailId)) {
      // Opening modal from back/forward navigation
      const id = detailId || state.detailId;
      if (currentDetailId !== id || !isDetailModalOpen) {
        setCurrentDetailId(id!);
        setIsDetailModalOpen(true);
        if (onOpenChange) onOpenChange(true);
      }
    } else {
      // Closing modal from back button
      if (isDetailModalOpen) {
        setIsDetailModalOpen(false);
        if (onOpenChange) onOpenChange(false);
        setCurrentDetailId(null);
        setRecentlyClosedId(null); // Clear any animation state
      }
    }
  }, [currentDetailId, isDetailModalOpen, onOpenChange]);
  
  // Check URL on mount for any detail parameter
  useEffect(() => {
    const detailId = searchParams.get('detailId');
    if (detailId) {
      setCurrentDetailId(detailId);
      setIsDetailModalOpen(true);
      if (onOpenChange) onOpenChange(true);
    }
  }, [searchParams, onOpenChange]);
  
  // Listen for modal open/close events
  useEffect(() => {
    console.log('SearchDetailModal: Setting up event listeners');
    
    // Define a direct event handler for debugging
    const directOpenHandler = (e: Event) => {
      console.log('SearchDetailModal: Direct openDetailModal event received', e);
      handleOpenDetailModal(e as CustomEvent<{ id: string, sourceRect: DOMRect | null }>);
    };
    
    // Add event listeners
    window.addEventListener('openDetailModal', directOpenHandler);
    window.addEventListener('closeDetailModal', handleCloseDetailModal as EventListener);
    window.addEventListener('popstate', handlePopState);
    
    console.log('SearchDetailModal: Event listeners set up');
    
    return () => {
      console.log('SearchDetailModal: Removing event listeners');
      window.removeEventListener('openDetailModal', directOpenHandler);
      window.removeEventListener('closeDetailModal', handleCloseDetailModal as EventListener);
      window.removeEventListener('popstate', handlePopState);
    };
  }, [handleCloseDetailModal, handleOpenDetailModal, handlePopState]);

  // Sync with external isOpen prop if provided
  useEffect(() => {
    if (externalIsOpen !== undefined && externalIsOpen !== isDetailModalOpen) {
      setIsDetailModalOpen(externalIsOpen);
    }
  }, [externalIsOpen, isDetailModalOpen]);

  return (
    <ListingDetailModal 
      isOpen={isDetailModalOpen}
      listingId={currentDetailId || ''}
      onClose={(event) => handleCloseDetailModal(event as CustomEvent<{ fromBackButton?: boolean }>)}
      sourceCardRect={lastViewedCardRect}
    />
  );
};
