import * as React from 'react';
import { useEffect } from 'react';
import { Icon } from '../../Icon';

/**
 * A search input component with a clear button
 */
export interface SearchInputProps {
  /** Current search value */
  value: string;
  /** Callback when search value changes */
  onChange: (value: string) => void;
  /** Placeholder text for the input */
  placeholder: string;
  /** Reference to the input element */
  inputRef: React.RefObject<HTMLInputElement>;
}

export const SearchInput: React.FC<SearchInputProps> = ({
  value,
  onChange,
  placeholder,
  inputRef
}) => {
  return (
    <div className="relative">
      <input
        type="text"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        className="w-full py-[5px] px-3 border border-gray-300 dark:border-neutral-700 rounded-md text-[13px] focus:ring-blue-800 focus:border-blue-800 dark:bg-neutral-800 dark:text-neutral-200"
        ref={inputRef}
      />
    </div>
  );
};

/**
 * Props for the SearchToggle component
 */
export interface SearchToggleProps {
  /** Current search value */
  value: string;
  /** Callback when search value changes */
  onChange: (value: string) => void;
  /** Whether the search input is currently visible */
  isVisible: boolean;
  /** Callback to toggle search input visibility */
  onToggle: () => void;
  /** Title of the section being searched */
  title: string;
  /** Placeholder text for the input */
  placeholder: string;
  /** Whether the toggle button should be disabled */
  disabled?: boolean;
  /** Reference to the input element */
  inputRef: React.RefObject<HTMLInputElement>;
  /** Optional right element (e.g., Clear All button) */
  rightElement?: React.ReactNode;
}

/**
 * A component that combines a search toggle button with a search input
 */
export const SearchToggle: React.FC<SearchToggleProps> = ({
  value,
  onChange,
  isVisible,
  onToggle,
  title,
  placeholder,
  disabled = false,
  inputRef,
  rightElement
}) => {
  // Focus the input when it becomes visible
  useEffect(() => {
    if (isVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isVisible, inputRef]);

  return (
    <div className="flex justify-between items-center">
      <div className="flex items-center">
        <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">{title}</h3>
        
        {!isVisible && (
          <button
            onClick={disabled ? undefined : onToggle}
            disabled={disabled}
            className={`ml-2 p-1.5 rounded-md text-[13px] 
              text-gray-500 hover:text-gray-700 dark:text-neutral-400 dark:hover:text-neutral-200
              transition-colors flex items-center gap-1.5 hover:bg-gray-100 dark:hover:bg-neutral-800 ${
              disabled ? 'opacity-50 cursor-not-allowed' : ''
            }`}
            aria-label={`Search ${title.toLowerCase()}`}
          >
            <Icon name="search" size={14} />
            <span className="font-medium">Search</span>
          </button>
        )}
      </div>
      
      {isVisible ? (
        <div className="flex-1 flex mx-2">
          <div className="inline-flex items-center">
            <div className="w-[75%]">
              <SearchInput 
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                inputRef={inputRef}
              />
            </div>
            <button
              onClick={() => {
                onChange('');
                onToggle();
              }}
              className="ml-1 p-1.5 rounded-md text-[13px] text-gray-500 hover:text-gray-700 dark:text-neutral-400 dark:hover:text-neutral-200 transition-colors hover:bg-gray-100 dark:hover:bg-neutral-800"
              aria-label="Clear and close search"
            >
              <Icon name="x" size={14} />
            </button>
          </div>
          <div className="flex-1"></div>
        </div>
      ) : (
        <div className="flex-1"></div>
      )}
      
      <div className="flex items-center gap-2 flex-shrink-0">
        {rightElement}
      </div>
    </div>
  );
};

export default SearchInput;
