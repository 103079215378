import { useRef } from 'react';
import { cn } from '../lib/utils';
import { useColumnCount, type ColumnCount } from '../hooks/useColumnCount';

// Breakpoint constants for responsive UI display only
const BREAKPOINTS = {
  SM: 640,    // Mobile to tablet
  MD: 1024,   // Tablet to desktop
  LG: 1280,   // Desktop to large desktop
} as const;

interface LayoutSwitcherProps {
  className?: string;
  variant?: 'inline' | 'standard';
  onLayoutChange: (count: ColumnCount) => void;
}

export const LayoutSwitcher = ({
  className = '',
  variant = 'standard',
  onLayoutChange
}: LayoutSwitcherProps) => {
  const [columnCount, setColumnCount] = useColumnCount();

  // Refs for layout buttons to measure their positions
  const buttonRefs = {
    1: useRef<HTMLButtonElement>(null),
    2: useRef<HTMLButtonElement>(null),
    3: useRef<HTMLButtonElement>(null),
    4: useRef<HTMLButtonElement>(null),
  };

  const handleColumnChange = (count: ColumnCount) => {
    setColumnCount(count);
    onLayoutChange(count);
  };

  return (
    <div 
      className={cn(
        "flex rounded-xl overflow-hidden border border-gray-300 dark:border-neutral-700 shadow-sm bg-white dark:bg-neutral-900 relative",
        variant === 'inline' ? 'h-10' : 'h-10',
        className
      )}
    >
      {/* Always show 1-column option */}
      <button
        key={1}
        ref={buttonRefs[1]}
        onClick={() => handleColumnChange(1)}
        className={cn(
          'w-10 flex items-center justify-center transition-colors duration-300 relative z-10',
          1 === columnCount
            ? 'text-blue-800 dark:text-blue-400 font-medium bg-blue-100 dark:bg-blue-900/30'
            : 'bg-transparent text-gray-700 dark:text-neutral-300 hover:bg-gray-50 dark:hover:bg-neutral-800'
        )}
        title="1 column"
      >
        <ColumnIcon count={1} />
      </button>

      {/* Show 2-column option on sm screens and larger */}
      <button
        key={2}
        ref={buttonRefs[2]}
        onClick={() => handleColumnChange(2)}
        className={cn(
          'w-10 flex items-center justify-center transition-colors duration-300 relative z-10 border-l border-gray-200 dark:border-neutral-700',
          2 === columnCount
            ? 'text-blue-800 dark:text-blue-400 font-medium bg-blue-100 dark:bg-blue-900/30'
            : 'bg-transparent text-gray-700 dark:text-neutral-300 hover:bg-gray-50 dark:hover:bg-neutral-800',
          'hidden sm:flex'
        )}
        title="2 columns"
      >
        <ColumnIcon count={2} />
      </button>

      {/* Show 3-column option on lg screens and larger */}
      <button
        key={3}
        ref={buttonRefs[3]}
        onClick={() => handleColumnChange(3)}
        className={cn(
          'w-10 flex items-center justify-center transition-colors duration-300 relative z-10 border-l border-gray-200 dark:border-neutral-700',
          3 === columnCount
            ? 'text-blue-800 dark:text-blue-400 font-medium bg-blue-100 dark:bg-blue-900/30'
            : 'bg-transparent text-gray-700 dark:text-neutral-300 hover:bg-gray-50 dark:hover:bg-neutral-800',
          'hidden lg:flex'
        )}
        title="3 columns"
      >
        <ColumnIcon count={3} />
      </button>

      {/* Show 4-column option on xl screens and larger */}
      <button
        key={4}
        ref={buttonRefs[4]}
        onClick={() => handleColumnChange(4)}
        className={cn(
          'w-10 flex items-center justify-center transition-colors duration-300 relative z-10 border-l border-gray-200 dark:border-neutral-700',
          4 === columnCount
            ? 'text-blue-800 dark:text-blue-400 font-medium bg-blue-100 dark:bg-blue-900/30'
            : 'bg-transparent text-gray-700 dark:text-neutral-300 hover:bg-gray-50 dark:hover:bg-neutral-800',
          'hidden xl:flex'
        )}
        title="4 columns"
      >
        <ColumnIcon count={4} />
      </button>
    </div>
  );
};

// Helper component for column layout icons
const ColumnIcon = ({ count }: { count: number }) => {
  const width = 16;
  const gap = 2;
  const columnWidth = (width - (gap * (count - 1))) / count;

  return (
    <svg 
      width={width} 
      height={width} 
      viewBox={`0 0 ${width} ${width}`} 
      fill="currentColor"
    >
      {Array.from({ length: count }).map((_, i) => (
        <rect
          key={i}
          x={i * (columnWidth + gap)}
          y={2}
          width={columnWidth}
          height={12}
          rx={1}
        />
      ))}
    </svg>
  );
}; 