import * as React from 'react';
import { useMemo } from 'react';
import { FilterPills } from '@/components/FilterPills';
import type { QueryState } from '@/types/queryState';
import type { AvailableFilters } from '@/hooks/useFilterHandlers';
import { RANGE_CONFIGS, getRangeDefaults } from '@/shared/range-config';
import type { RangeConfig } from '@/shared/range-config';
import type { EquipmentCategory } from '@/types/api';

/**
 * Defines the structure for an active filter pill.
 */
export interface ActiveFilter {
  type: 'make' | 'modelFamily' | 'model' | 'range' | 'equipment' | 'condition' | 'saleStatus' | 'intendedUse' | 'category';
  label: string;
  onRemove: () => void;
  value?: string | number | [number, number];
  data?: any;
}

/**
 * Props for the FilterPillDisplay component.
 */
interface FilterPillDisplayProps {
  currentFilters: QueryState;
  availableFilters: AvailableFilters;
  onFilterChange: (filters: Partial<QueryState>) => void;
  onClearAll: () => void;
}

/**
 * Utility function to format price.
 * @param value - The numeric price value.
 * @returns Formatted price string (e.g., $1.2M, $500K, $250).
 */
const formatPrice = (value: number): string => {
  if (value >= 1000000) {
    return `$${(value / 1000000).toFixed(1)}M`;
  } else if (value >= 1000) {
    return `$${(value / 1000).toFixed(0)}K`;
  } else {
    return `$${value}`;
  }
};

/**
 * Generates an array of active filter objects for display as pills.
 * @param currentFilters - The current query state.
 * @param availableFilters - The available filter options.
 * @param onFilterChange - Callback to update filters.
 * @returns An array of ActiveFilter objects.
 */
const getActiveFilters = (
  currentFilters: QueryState,
  availableFilters: AvailableFilters,
  onFilterChange: (filters: Partial<QueryState>) => void
): ActiveFilter[] => {
  const filters: ActiveFilter[] = [];

  // --- Replicated logic from SearchAndFilter.getActiveFilters --- 

  // Add makes filters
  currentFilters.makes.forEach(make => {
    const makeObj = availableFilters.makes.find(m => m.value === make);
    filters.push({
      type: 'make',
      label: `Make: ${makeObj?.label || make}`,
      onRemove: () => {
        const updatedMakes = currentFilters.makes.filter(m => m !== make);
        if (updatedMakes.length === 0) {
          onFilterChange({ makes: [], modelFamilies: [], models: [] });
        } else {
          onFilterChange({ makes: updatedMakes });
        }
      }
    });
  });

  // Add model family filters
  const selectedModelFamilies = useMemo(() => 
    availableFilters.modelFamilies.filter(f => 
      currentFilters.modelFamilies.includes(f.value) &&
      (currentFilters.makes.length === 0 || currentFilters.makes.includes(f.make))
    ), [availableFilters.modelFamilies, currentFilters.modelFamilies, currentFilters.makes]);

  selectedModelFamilies.forEach(family => {
    filters.push({
      type: 'modelFamily',
      label: `Family: ${family.label}`,
      onRemove: () => {
        const updatedFamilies = currentFilters.modelFamilies.filter(f => f !== family.value);
        onFilterChange({ modelFamilies: updatedFamilies });
      },
      data: {
        make: family.make
      }
    });
  });

  // Add model filters
  const selectedModels = useMemo(() => 
    availableFilters.models.filter(m => 
      currentFilters.models.includes(m.value) && 
      (currentFilters.makes.length === 0 || currentFilters.makes.includes(m.make)) &&
      (currentFilters.modelFamilies.length === 0 || currentFilters.modelFamilies.includes(m.modelFamily))
    ), [availableFilters.models, currentFilters.models, currentFilters.makes, currentFilters.modelFamilies]);

  selectedModels.forEach(model => {
    filters.push({
      type: 'model',
      label: `Model: ${model.label}`,
      onRemove: () => {
        const updatedModels = currentFilters.models.filter(m => m !== model.value);
        onFilterChange({ models: updatedModels });
      },
      data: {
        make: model.make,
        modelFamily: model.modelFamily
      }
    });
  });

  // Add equipment filters
  Object.entries(currentFilters.equipment || {})
    .filter(([, value]) => value)
    .forEach(([key]) => {
      const equipmentLabel = key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase());
      filters.push({
        type: 'equipment',
        label: `${equipmentLabel}: Yes`,
        onRemove: () => {
          onFilterChange({ equipment: { ...currentFilters.equipment, [key]: false } });
        }
      });
    });

  // Add condition filters
  if (currentFilters.condition) {
    const conditionLabel = availableFilters.conditions.find(c => c.value === currentFilters.condition)?.label || currentFilters.condition;
    filters.push({
      type: 'condition',
      label: `Condition: ${conditionLabel}`,
      onRemove: () => {
        onFilterChange({ condition: '' });
      }
    });
  }

  // Add sale status filters
  if (currentFilters.saleStatus) {
    const statusLabel = availableFilters.saleStatuses.find(s => s.value === currentFilters.saleStatus)?.label || currentFilters.saleStatus;
    filters.push({
      type: 'saleStatus',
      label: `Status: ${statusLabel}`,
      onRemove: () => {
        onFilterChange({ saleStatus: '' });
      }
    });
  }

  // Add intended use filters
  currentFilters.intendedUse.forEach(useValue => {
    const useLabel = availableFilters.intendedUses.find(u => u.value === useValue)?.label || useValue;
    filters.push({
      type: 'intendedUse',
      label: `Use: ${useLabel}`,
      onRemove: () => {
        const updatedUses = currentFilters.intendedUse.filter(u => u !== useValue);
        onFilterChange({ intendedUse: updatedUses });
      }
    });
  });

  // Add category filters
  if (currentFilters.category) {
    const categoryLabel = availableFilters.categories.find(c => c.value === currentFilters.category)?.label || currentFilters.category;
    filters.push({
      type: 'category',
      label: `Category: ${categoryLabel}`,
      onRemove: () => {
        onFilterChange({ category: '' });
      }
    });
  }

  // Add range filters (Price, Year, Engine Time, Useful Load)
  Object.keys(RANGE_CONFIGS).forEach((key) => {
    const rangeKey = key as keyof Pick<QueryState, 'priceRange' | 'yearRange' | 'engineTimeRange' | 'usefulLoadRange'>;
    const currentRange = currentFilters[rangeKey] as [number, number];
    const defaultRange = getRangeDefaults(rangeKey);

    if (JSON.stringify(currentRange) !== JSON.stringify(defaultRange)) {
      let labelPrefix = '';
      let labelValue = '';

      switch (rangeKey) {
        case 'priceRange':
          labelPrefix = 'Price';
          if (currentFilters.showZeroPrice && currentRange[0] === 0) {
            labelValue = 'Incl. $0';
          } else {
            labelValue = `${formatPrice(currentRange[0])} - ${formatPrice(currentRange[1])}`;
          }
          break;
        case 'yearRange':
          labelPrefix = 'Year';
          labelValue = `${currentRange[0]} - ${currentRange[1]}`;
          break;
        case 'engineTimeRange':
          labelPrefix = 'Engine Hours';
          labelValue = `${currentRange[0].toLocaleString()} - ${currentRange[1].toLocaleString()}`;
          break;
        case 'usefulLoadRange':
          labelPrefix = 'Useful Load';
          labelValue = `${currentRange[0].toLocaleString()} - ${currentRange[1].toLocaleString()} lbs`;
          break;
      }

      if (labelPrefix && labelValue) {
        filters.push({
          type: 'range',
          label: `${labelPrefix}: ${labelValue}`,
          onRemove: () => {
            const changes: Partial<QueryState> = { [rangeKey]: defaultRange };
            if (rangeKey === 'priceRange' && currentFilters.showZeroPrice && currentRange[0] === 0) {
              changes.showZeroPrice = false;
            }
            onFilterChange(changes);
          }
        });
      }
    }
  });

  // --- Separate Pills for boolean toggles ---

  // Show Zero Price Pill (only if price range is at default but this is true)
  if (currentFilters.showZeroPrice && JSON.stringify(currentFilters.priceRange) === JSON.stringify(getRangeDefaults('priceRange'))) {
    filters.push({
      type: 'range',
      label: `Price: Incl. $0`,
      onRemove: () => {
        onFilterChange({ showZeroPrice: false });
      }
    });
  }

  // Show Sold Pill
  if (currentFilters.showSold) {
    filters.push({
      type: 'range',
      label: 'Include Sold Aircraft',
      onRemove: () => {
        onFilterChange({ showSold: false });
      }
    });
  }

  return filters;
};

/**
 * Component responsible for calculating and displaying active filter pills.
 */
export const FilterPillDisplay: React.FC<FilterPillDisplayProps> = ({ 
  currentFilters,
  availableFilters,
  onFilterChange,
  onClearAll
 }) => {

  const activeFilters = useMemo(() => 
    getActiveFilters(currentFilters, availableFilters, onFilterChange), 
    [currentFilters, availableFilters, onFilterChange]
  );

  if (activeFilters.length === 0) {
    return null; // Don't render anything if no filters are active
  }

  return (
    <div className="mb-4">
      <FilterPills 
        filters={activeFilters}
        onClearAll={onClearAll}
        showClearAll={true}
      />
    </div>
  );
};
