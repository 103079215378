import * as React from 'react';
import { useState, useEffect } from 'react';
import RangeSliderInput from 'react-range-slider-input';
import 'react-range-slider-input/dist/style.css';

export interface RangeSliderProps {
  /** Current range values */
  value: [number, number];
  /** Callback when range values change */
  onChange: (values: [number, number]) => void;
  /** Minimum value for the range slider */
  min: number;
  /** Maximum value for the range slider */
  max: number;
  /** Step size for the slider */
  step: number;
  /** Label to display above the slider */
  label: string;
  /** Function to format the minimum value for display */
  formatMin: (val: number) => string;
  /** Function to format the maximum value for display */
  formatMax: (val: number) => string;
}

/**
 * A reusable range slider component with formatted values display
 */
export const RangeSlider: React.FC<RangeSliderProps> = ({ 
  value,
  onChange,
  min,
  max,
  step,
  label,
  formatMin,
  formatMax
}) => {
  // Local state for range values during dragging
  const [localValues, setLocalValues] = useState<[number, number]>(value);
  
  // Update local values when props change
  useEffect(() => {
    setLocalValues(value);
  }, [value]);

  // Handle slider input
  const handleInput = (values: number[]) => {
    setLocalValues(values as [number, number]);
  };

  // Handle thumbs drag end - commit the change to parent
  const handleThumbDragEnd = () => {
    onChange(localValues);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">{label}</h3>
        <div className="text-sm font-medium text-gray-500 dark:text-neutral-300">
          <span>{formatMin(localValues[0])}</span>
          <span>-</span>
          <span>{formatMax(localValues[1])}</span>
        </div>
      </div>
      <div className="py-2 px-1">
        <RangeSliderInput
          value={localValues}
          onInput={handleInput}
          onThumbDragEnd={handleThumbDragEnd}
          min={min}
          max={max}
          step={step}
        />
      </div>

      {/* Range Slider Styles */}
      <style>{`
        .range-slider {
          height: 4px !important;
          padding: 0 !important;
          background: var(--tw-neutral-200) !important;
        }
        
        .dark .range-slider {
          background: var(--tw-neutral-800) !important;
        }
        
        .range-slider .range-slider__range {
          background: var(--tw-blue-800) !important;
          height: 4px !important;
        }
        
        .range-slider .range-slider__thumb {
          width: 1.25rem !important;
          height: 1.25rem !important;
          background: var(--tw-white) !important;
          border: 2px solid var(--tw-blue-800) !important;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2) !important;
        }

        .dark .range-slider .range-slider__thumb {
          background: var(--tw-neutral-100) !important;
          border: 2px solid var(--tw-blue-800) !important;
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.4) !important;
        }
      `}</style>
    </div>
  );
};

export default RangeSlider;
