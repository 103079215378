import { useState } from 'react';
import { Pill } from './Pill';
import { cn, pillStyles } from '../../../lib/utils';
import type { Option } from '../../../types/api';

export interface PillGroupProps {
  options: Array<Option & { disabled?: boolean }>;
  selectedValues: string[];
  onChange: (selected: Option[]) => void;
  maxDisplay?: number;
  emptyMessage?: string;
  isModelSection?: boolean;
  isMakeSection?: boolean;
}

export const PillGroup = ({ 
  options, 
  selectedValues, 
  onChange, 
  maxDisplay = 1000,
  emptyMessage = "No options available",
  isModelSection = false,
  isMakeSection = false
}: PillGroupProps) => {
  // State to track if we're showing all options
  const [showAll, setShowAll] = useState(false);
  
  // Filter to only valid options with counts
  const validOptions = options.filter(opt => opt && typeof opt.value !== 'undefined' && opt.label);
  
  // Sort options based on section type
  const sortedOptions = [...validOptions].sort((a, b) => {
    // For model sections, prioritize alphabetical sorting
    if (isModelSection) {
      return (a.label || '').localeCompare(b.label || '');
    }
    
    // For other sections (including make), sort by count first, then alphabetically
    if ((b.count || 0) !== (a.count || 0)) {
      return (b.count || 0) - (a.count || 0);
    }
    // Then alphabetically
    return (a.label || '').localeCompare(b.label || '');
  });
  
  // Determine which options to display based on maxDisplay and showAll
  const displayOptions = showAll ? sortedOptions : sortedOptions.slice(0, maxDisplay);
  
  // Check if we need a "show more" button
  const hasMoreOptions = sortedOptions.length > maxDisplay;
  
  // Count selected options that would be hidden when collapsed
  const hiddenSelectedCount = !showAll 
    ? selectedValues.filter(value => 
        !displayOptions.some(opt => String(opt.value) === value)
      ).length
    : 0;
  
  // Handler for toggling a pill
  const handlePillClick = (option: Option) => {
    const value = String(option.value).toLowerCase();
    const isCurrentlySelected = selectedValues.includes(value);
    
    if (isCurrentlySelected) {
      // Remove from selection
      const newSelected = selectedValues.filter(v => v !== value);
      // Find matching option objects for the remaining selected values
      const selectedOptions = sortedOptions.filter(opt => 
        newSelected.includes(String(opt.value).toLowerCase())
      );
      onChange(selectedOptions);
    } else {
      // Add to selection
      const newSelected = [...selectedValues, value];
      // Get the current option being added - use case-insensitive matching
      const newOption = sortedOptions.find(opt => 
        String(opt.value).toLowerCase() === value
      );
      // Get existing selected options
      const existingOptions = sortedOptions.filter(opt => 
        selectedValues.includes(String(opt.value).toLowerCase())
      );
      // Combine existing options with the new one
      onChange(newOption ? [...existingOptions, newOption] : existingOptions);
    }
  };
  
  if (validOptions.length === 0) {
    return <p className="text-sm text-gray-500 dark:text-neutral-400 p-2">{emptyMessage}</p>;
  }
  
  return (
    <div className="flex flex-wrap -m-1">
      {displayOptions.map(option => (
        <Pill
          key={String(option.value)}
          label={String(option.label)}
          count={option.count}
          isSelected={selectedValues.includes(String(option.value).toLowerCase())}
          onClick={() => handlePillClick(option)}
          disabled={option.disabled}
          isModel={isModelSection}
          isMake={isMakeSection}
        />
      ))}
      
      {hasMoreOptions && (
        <button
          type="button"
          onClick={() => setShowAll(!showAll)}
          className={cn(
            pillStyles.base,
            pillStyles.sizes.md,
            pillStyles.variants.default,
            'border border-gray-200 dark:border-neutral-800'
          )}
        >
          {showAll ? (
            <span className="inline-flex items-center leading-none">
              Show Less
              {hiddenSelectedCount > 0 && (
                <span className="ml-2 inline-flex items-center justify-center w-5 h-5 rounded-full text-xs font-medium bg-blue-200 text-blue-900 dark:bg-blue-800 dark:text-blue-50">
                  {hiddenSelectedCount}
                </span>
              )}
            </span>
          ) : (
            <span className="inline-flex items-center leading-none">
              Show {sortedOptions.length - maxDisplay} More
              {hiddenSelectedCount > 0 && (
                <span className="ml-2 inline-flex items-center justify-center w-5 h-5 rounded-full text-xs font-medium bg-blue-200 text-blue-900 dark:bg-blue-800 dark:text-blue-50">
                  {hiddenSelectedCount}
                </span>
              )}
            </span>
          )}
        </button>
      )}
    </div>
  );
};
