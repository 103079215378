import { useState, useEffect, useRef, useCallback, useMemo, memo } from 'react';
import * as React from 'react';
import { cn, pillStyles, buttonStyles } from '@/lib/utils';
import { Icon } from '@/components/Icon';
import { LayoutSwitcher } from '@/components/LayoutSwitcher';
import { DarkModeToggle } from '@/components/DarkModeToggle';
// EquipmentFilter is now imported from the filter/equipment directory
// @ts-ignore - Suppress likely incorrect TS error after clean install
import NumberFlow, { useCanAnimate } from '@number-flow/react';
import { motion } from 'framer-motion';
import type { Option } from '@/types/api';
import type { QueryState, SortOption } from '@/types/queryState';
import { equipmentCategories } from '@/types/api';
import type { EquipmentCategory } from '@/types/api';
import { SelectDropdown, defaultFormatOptionLabel, SelectOption as DropdownOption } from '@/components/SelectDropdown';
import { getScrollbarWidth } from '@/utils/scrollbarWidth'; // Assuming this exists and is correct
import { RANGE_CONFIGS } from '@/shared/range-config.js';

// Extended option types
type ModelFamilyOption = Option & { make?: string; modelFamily?: string };
type ModelOption = Option & { make?: string; modelFamily?: string };

// Import common UI components
import { Pill, PillGroup, SinglePillGroup } from '@/components/common/pills';
import { Accordion } from '@/components/common/accordion';
import { RangeSlider } from '@/components/common/range-slider';
import { SearchToggle } from '@/components/common/search-input';
import { Toggle } from '@/components/common/toggle';

// Import filter section components
import { SortLayout, sortOptions } from './sections/sort-layout';
import { AircraftType } from './sections/aircraft-type';
import { MakeModelYear } from './sections/make-model-year';
import { PriceConditionStatus } from './sections/price-condition-status';
import { Specifications } from './sections/specifications';
import { AvionicsAndEquipment } from './sections/avionics-and-equipment';
import { Footer } from './sections/footer';

// Create motion version of NumberFlow
const MotionNumberFlow = motion(NumberFlow);

interface FilterModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedMakes: Option[];
  selectedModelFamilies: ModelFamilyOption[];
  selectedModels: ModelOption[];
  ranges: {
    price: [number, number];
    year: [number, number];
    engineTime: [number, number];
    usefulLoad: [number, number];
  };
  availableFilters: {
    makes: Option[];
    modelFamilies: ModelFamilyOption[];
    models: ModelOption[];
    years: { min: number; max: number };
    conditions?: Option[];
    saleStatuses?: Option[];
    intendedUses?: Option[];
    categories?: Option[];
  };
  onMakeChange: (selected: Option | Option[] | null) => void;
  onModelFamilyChange: (selected: ModelFamilyOption | ModelFamilyOption[] | null) => void;
  onModelChange: (selected: ModelOption | ModelOption[] | null) => void;
  onRangeChange: (type: 'price' | 'year' | 'engineTime' | 'usefulLoad', values: [number, number]) => void;
  showZeroPrice: boolean;
  onShowZeroPriceChange: (checked: boolean) => void;
  totalMatches: number;
  onClearAll: () => void;
  onFilterChange: (filters: Partial<QueryState>) => void;
  equipment: Record<EquipmentCategory, boolean>;
  onEquipmentChange: (equipment: Record<EquipmentCategory, boolean>) => void;
  
  selectedCondition?: Option | null;
  selectedSaleStatuses: Option[];
  selectedIntendedUses: Option[];
  selectedCategory?: Option | null;
  onConditionChange: (selected: Option | null) => void;
  onSaleStatusChange: (selected: Option | Option[] | null) => void;
  onIntendedUseChange: (selected: Option | Option[] | null) => void;
  onCategoryChange: (selected: Option | null) => void;
  sort: SortOption;
  onSortChange: (sort: SortOption) => void;
  columnCount: 1 | 2 | 3 | 4;
  onLayoutChange: (count: 1 | 2 | 3 | 4) => void;
  onCustomizeClick: () => void;
  showSold: boolean;
  onShowSoldChange: (checked: boolean) => void;
}

// Add this utility function to safely create dropdown options
const createDropdownOption = (option: any): DropdownOption => {
  return {
    value: String(option.value || ''),
    label: String(option.label || option.value || ''),
    count: option.count || 0,
    disabled: !!option.disabled
  };
};

// sortOptions is now imported from './filter/sort-layout'

// Helper function to format sort options
const formatSortOptionLabel = (option: { value: string; label: string }) => (
  <div className="flex items-center py-2 px-3">
    <span>{option.label}</span>
  </div>
);

export const FilterModal = memo(({
  isOpen,
  onClose,
  selectedMakes,
  selectedModelFamilies,
  selectedModels,
  ranges,
  availableFilters,
  onMakeChange,
  onModelFamilyChange,
  onModelChange,
  onRangeChange,
  showZeroPrice,
  onShowZeroPriceChange,
  totalMatches,
  onClearAll,
  onFilterChange,
  equipment,
  onEquipmentChange,
  selectedCondition = null,
  selectedSaleStatuses = [],
  selectedIntendedUses = [],
  selectedCategory = null,
  onConditionChange,
  onSaleStatusChange,
  onIntendedUseChange,
  onCategoryChange,
  sort,
  onSortChange,
  columnCount,
  onLayoutChange,
  onCustomizeClick,
  showSold,
  onShowSoldChange
}: FilterModalProps) => {
  // Search state for filtering pills
  const [makeSearch, setMakeSearch] = useState('');
  const [modelFamilySearch, setModelFamilySearch] = useState('');
  const [modelSearch, setModelSearch] = useState('');
  
  // State to track search input visibility
  const [showMakeSearch, setShowMakeSearch] = useState(false);
  const [showModelFamilySearch, setShowModelFamilySearch] = useState(false);
  const [showModelSearch, setShowModelSearch] = useState(false);
  
  // State for active accordion
  const [activeAccordion, setActiveAccordion] = useState<string | null>(null);
  
  // Refs for focusing search inputs
  const makeSearchRef = useRef<HTMLInputElement>(null);
  const modelFamilySearchRef = useRef<HTMLInputElement>(null);
  const modelSearchRef = useRef<HTMLInputElement>(null);
  
  // Local state for range values during dragging
  const [localRanges, setLocalRanges] = useState({
    price: ranges.price,
    year: ranges.year,
    engineTime: ranges.engineTime,
    usefulLoad: ranges.usefulLoad
  });

  // Effect to focus the search input when it becomes visible
  useEffect(() => {
    if (showMakeSearch && makeSearchRef.current) {
      makeSearchRef.current.focus();
    }
  }, [showMakeSearch]);
  
  useEffect(() => {
    if (showModelFamilySearch && modelFamilySearchRef.current) {
      modelFamilySearchRef.current.focus();
    }
  }, [showModelFamilySearch]);
  
  useEffect(() => {
    if (showModelSearch && modelSearchRef.current) {
      modelSearchRef.current.focus();
    }
  }, [showModelSearch]);

  // Keep local ranges in sync with prop ranges when props change
  useEffect(() => {
    setLocalRanges({
      price: ranges.price,
      year: ranges.year,
      engineTime: ranges.engineTime,
      usefulLoad: ranges.usefulLoad
    });
  }, [ranges]);

  // Track active filters count for each section
  const activeFilterCounts = useMemo(() => {
    return {
      aircraftType: [
        selectedCategory,
        ...selectedIntendedUses
      ].filter(Boolean).length,
      makeModelYear: [
        ...selectedMakes, 
        ...selectedModelFamilies, 
        ...selectedModels
      ].length + (
        ranges.year[0] !== RANGE_CONFIGS.yearRange.min || 
        ranges.year[1] !== RANGE_CONFIGS.yearRange.max ? 1 : 0
      ),
      priceConditionStatus: (
        (ranges.price[0] !== RANGE_CONFIGS.priceRange.min || 
         ranges.price[1] !== RANGE_CONFIGS.priceRange.max ? 1 : 0) +
        (showZeroPrice ? 1 : 0) +
        (showSold ? 1 : 0) +
        (selectedCondition ? 1 : 0) +
        (selectedSaleStatuses.length > 0 ? 1 : 0)
      ),
      specifications: (
        (ranges.engineTime[0] !== RANGE_CONFIGS.engineTimeRange.min || 
         ranges.engineTime[1] !== RANGE_CONFIGS.engineTimeRange.max ? 1 : 0) +
        (ranges.usefulLoad[0] !== RANGE_CONFIGS.usefulLoadRange.min || 
         ranges.usefulLoad[1] !== RANGE_CONFIGS.usefulLoadRange.max ? 1 : 0)
      ),
      equipment: Object.values(equipment).filter(Boolean).length
    };
  }, [
    selectedMakes, selectedModelFamilies, selectedModels, ranges,
    showZeroPrice, showSold, equipment, selectedCondition, selectedSaleStatuses,
    selectedIntendedUses, selectedCategory
  ]);

  // Memoize filtered makes - DO NOT filter by any selection
  const filteredMakes = useMemo(() => {
    if (!availableFilters?.makes?.length) return [];
    
    const search = makeSearch.toLowerCase().trim();
    
    // Display all makes, only filtering by search term
    return [...availableFilters.makes]
      .filter(make => !search || (make.label?.toLowerCase().includes(search)))
      .sort((a, b) => a.label.localeCompare(b.label));
  }, [availableFilters.makes, makeSearch]);

  // Memoize filtered model families - only filter by selected makes, never by selected models
  const filteredModelFamilies = useMemo(() => {
    if (!availableFilters?.modelFamilies?.length) {
      return [];
    }

    // If no makes are selected, return empty array (keep this behavior)
    if (!selectedMakes?.length) {
      return [];
    }

    const selectedMakeValues = selectedMakes.map(make => make.value?.toString().toUpperCase() || '');
    const search = modelFamilySearch.toLowerCase().trim();

    // Only filter by make, never by model selection
    return availableFilters.modelFamilies
      .filter(modelFamily => 
        modelFamily?.make && 
        selectedMakeValues.includes(modelFamily.make.toUpperCase()) &&
        (!search || modelFamily.label?.toLowerCase().includes(search))
      );
  }, [selectedMakes, availableFilters.modelFamilies, modelFamilySearch]);
  
  // Create a mapping between model family values and their corresponding make
  const modelFamilyToMakeMap = useMemo(() => {
    const map: Record<string, string> = {};
    
    if (availableFilters?.modelFamilies?.length) {
      availableFilters.modelFamilies.forEach(modelFamily => {
        if (modelFamily.value && modelFamily.make) {
          map[String(modelFamily.value).toUpperCase()] = modelFamily.make.toUpperCase();
        }
      });
    }
    
    return map;
  }, [availableFilters.modelFamilies]);
  
  // Create a mapping between model values and their corresponding model family and make
  const modelToFamilyMap = useMemo(() => {
    const map: Record<string, { modelFamily: string, make: string }> = {};
    
    if (availableFilters?.models?.length) {
      availableFilters.models.forEach(model => {
        if (model.value && model.modelFamily && model.make) {
          map[String(model.value).toUpperCase()] = {
            modelFamily: model.modelFamily.toUpperCase(),
            make: model.make.toUpperCase()
          };
        }
      });
    }
    
    return map;
  }, [availableFilters.models]);
  
  // Ensure all selected model families have their make property set
  const enhancedSelectedModelFamilies = useMemo(() => {
    return selectedModelFamilies.map(modelFamily => {
      if (modelFamily.make) return modelFamily;
      
      const make = modelFamilyToMakeMap[String(modelFamily.value).toUpperCase()];
      return make ? { ...modelFamily, make } : modelFamily;
    });
  }, [selectedModelFamilies, modelFamilyToMakeMap]);
  
  // Ensure all selected models have their model family and make properties set
  const enhancedSelectedModels = useMemo(() => {
    return selectedModels.map(model => {
      // Use type assertion to tell TypeScript about the optional properties
      const modelWithFamily = model as ModelOption;
      
      if (modelWithFamily.modelFamily && modelWithFamily.make) return modelWithFamily;
      
      const modelInfo = modelToFamilyMap[String(model.value).toUpperCase()];
      if (modelInfo) {
        return {
          ...model,
          modelFamily: modelInfo.modelFamily,
          make: modelInfo.make
        } as ModelOption;
      }
      
      return modelWithFamily;
    }) as Array<ModelOption>;
  }, [selectedModels, modelToFamilyMap]);

  // Memoize filtered models - filter normally by both make and model family, but don't filter by count
  const filteredModels = useMemo(() => {
    if (!availableFilters?.models?.length) {
      return [];
    }

    // If no model families are selected, return empty array
    if (!enhancedSelectedModelFamilies.length) {
      return [];
    }

    const selectedMakeValues = selectedMakes.map(make => make.value?.toString().toUpperCase() || '');
    const selectedModelFamilyValues = enhancedSelectedModelFamilies.map(modelFamily => modelFamily.value?.toString().toUpperCase() || '');
    const search = modelSearch.toLowerCase().trim();
    
    // Filter by selected makes and model families, but don't filter by count
    return availableFilters.models
      .filter(model => {
        return model?.make && 
               selectedMakeValues.includes(model.make.toUpperCase()) && 
               model.modelFamily && 
               selectedModelFamilyValues.includes(model.modelFamily.toUpperCase()) && 
               (!search || model.label?.toLowerCase().includes(search));
      });
  }, [selectedMakes, enhancedSelectedModelFamilies, availableFilters.models, modelSearch]);

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  };

  const handleClearAll = () => {
    onClearAll();
  };

  // Prevent scrolling in the background when drawer is open
  useEffect(() => {
    if (isOpen) {
      // Check if we actually have a scrollbar before applying compensation
      const hasScrollbar = document.documentElement.scrollHeight > document.documentElement.clientHeight;
      // Get cached scrollbar width from our utility
      const scrollbarWidth = hasScrollbar ? getScrollbarWidth() : 0;
      
      // Store the current scroll position
      const scrollY = window.scrollY;
      
      // Disable scrolling on html element (not body)
      document.documentElement.style.overflow = 'hidden';
      
      // Add padding to compensate for scrollbar removal (prevents layout shift)
      if (hasScrollbar && scrollbarWidth > 0) {
        document.documentElement.style.paddingRight = `${scrollbarWidth}px`;
      }
      
      return () => {
        // Re-enable scrolling when drawer closes
        document.documentElement.style.overflow = '';
        document.documentElement.style.paddingRight = '';
        
        // Restore scroll position if needed
        window.scrollTo(0, scrollY);
      };
    }
  }, [isOpen]);

  // Check if NumberFlow can animate
  const canAnimate = useCanAnimate();

  // Render range slider with formatted values
  const renderRangeSlider = (
    type: 'price' | 'year' | 'engineTime' | 'usefulLoad',
    label: string,
    formatMin: (val: number) => string,
    formatMax: (val: number) => string
  ) => {
    const config = 
      type === 'price' ? RANGE_CONFIGS.priceRange :
      type === 'year' ? RANGE_CONFIGS.yearRange :
      type === 'engineTime' ? RANGE_CONFIGS.engineTimeRange :
      RANGE_CONFIGS.usefulLoadRange;
      
    return (
      <RangeSlider
        value={localRanges[type]}
        onChange={(values) => {
          setLocalRanges(prev => ({ ...prev, [type]: values }));
          onRangeChange(type, values);
        }}
        min={config.min}
        max={config.max}
        step={config.step}
        label={label}
        formatMin={formatMin}
        formatMax={formatMax}
      />
    );
  };
  










  return (
    <div 
      className={`fixed inset-0 z-[60] overflow-hidden transition-opacity duration-500 ease-out ${
        isOpen ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'
      }`}
      onMouseDown={handleBackdropClick}
    >
      {/* Simple backdrop without blur - using layered semi-transparent backgrounds instead */}
      <div className="absolute inset-0" onMouseDown={handleBackdropClick}>
        {/* Base dark layer */}
        <div className="absolute inset-0 bg-black/50" onMouseDown={handleBackdropClick}></div>
        
        {/* Optional subtle texture for depth (very light) - make sure it doesn't block clicks */}
        {isOpen && (
          <div 
            className="absolute inset-0 bg-gradient-to-br from-black/5 to-transparent pointer-events-none"
          ></div>
        )}
      </div>
      
      {/* Drawer Container */}
      <div 
        className={cn(
          "fixed inset-y-0 right-0 w-full sm:w-[400px] md:w-[450px] lg:w-[500px] max-w-full bg-white dark:bg-neutral-900 shadow-xl transform transition-transform duration-500 ease-out overflow-hidden flex flex-col drawer-container", 
          isOpen ? "translate-x-0" : "translate-x-full"
        )}
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="sticky top-0 z-10 bg-white dark:bg-neutral-900 border-b border-gray-300 dark:border-neutral-800 drawer-header">
          <div className="flex items-center justify-between py-2 px-5">
            <h2 
              className="text-sm font-semibold text-gray-900 dark:text-neutral-100 flex-1 text-center"
              style={{
                fontSize: 'clamp(1.14rem, 3.25vh, 1.24rem)'
              }}
            >
              Refine Your Search
            </h2>
            <button
              onClick={onClose}
              className="p-1 rounded-full hover:bg-gray-100 dark:hover:bg-neutral-800 text-gray-500 dark:text-neutral-400"
              aria-label="Close"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>

        {/* Content Area with Accordions */}
        <div className="flex-1 overflow-y-auto">
          {/* Sort and Layout Accordion */}
          <Accordion 
            title="Sort and Layout" 
            count={0}
            isOpen={activeAccordion === "Sort and Layout"}
            onToggle={(title) => setActiveAccordion(activeAccordion === title ? null : title)}
            icon={
              <Icon name="arrow-down-wide-narrow" size={20} className="text-gray-900 dark:text-neutral-100" />
            }
          >
            <SortLayout
              sort={sort}
              onSortChange={onSortChange}
              onLayoutChange={onLayoutChange}
              onCustomizeClick={onCustomizeClick}
            />
          </Accordion>

          <Accordion 
            title="Aircraft Type" 
            count={activeFilterCounts.aircraftType}
            isOpen={activeAccordion === "Aircraft Type"}
            onToggle={(title) => setActiveAccordion(activeAccordion === title ? null : title)}
            icon={
              <Icon name="plane-takeoff" size={20} className="text-gray-900 dark:text-neutral-100" />
            }
          >
            <AircraftType
              selectedCategory={selectedCategory}
              onCategoryChange={onCategoryChange}
              selectedIntendedUses={selectedIntendedUses}
              onIntendedUseChange={onIntendedUseChange}
              availableFilters={{
                categories: availableFilters.categories,
                intendedUses: availableFilters.intendedUses
              }}
            />
          </Accordion>
          
          <Accordion 
            title="Make / Model / Year" 
            count={activeFilterCounts.makeModelYear}
            isOpen={activeAccordion === "Make / Model / Year"}
            onToggle={(title) => setActiveAccordion(activeAccordion === title ? null : title)}
            icon={
              <Icon name="cog" size={20} className="text-gray-900 dark:text-neutral-100" />
            }
          >
            <MakeModelYear
              selectedMakes={selectedMakes}
              selectedModelFamilies={selectedModelFamilies}
              selectedModels={selectedModels}
              yearRange={ranges.year}
              availableFilters={{
                makes: availableFilters.makes || [],
                modelFamilies: availableFilters.modelFamilies || [],
                models: availableFilters.models || [],
                years: availableFilters.years || { min: 1950, max: new Date().getFullYear() + 1 }
              }}
              filteredMakes={filteredMakes}
              filteredModelFamilies={filteredModelFamilies}
              filteredModels={filteredModels}
              enhancedSelectedModelFamilies={enhancedSelectedModelFamilies}
              enhancedSelectedModels={enhancedSelectedModels}
              onMakeChange={onMakeChange}
              onModelFamilyChange={onModelFamilyChange}
              onModelChange={onModelChange}
              onYearChange={(values) => onRangeChange('year', values)}
              makeSearch={makeSearch}
              modelFamilySearch={modelFamilySearch}
              modelSearch={modelSearch}
              setMakeSearch={setMakeSearch}
              setModelFamilySearch={setModelFamilySearch}
              setModelSearch={setModelSearch}
              showMakeSearch={showMakeSearch}
              showModelFamilySearch={showModelFamilySearch}
              showModelSearch={showModelSearch}
              setShowMakeSearch={setShowMakeSearch}
              setShowModelFamilySearch={setShowModelFamilySearch}
              setShowModelSearch={setShowModelSearch}
              makeSearchRef={makeSearchRef}
              modelFamilySearchRef={modelFamilySearchRef}
              modelSearchRef={modelSearchRef}
            />
          </Accordion>
          
          <Accordion 
            title="Price / Condition / Status" 
            count={activeFilterCounts.priceConditionStatus}
            isOpen={activeAccordion === "Price / Condition / Status"}
            onToggle={(title) => setActiveAccordion(activeAccordion === title ? null : title)}
            icon={
              <Icon name="circle-dollar-sign" size={20} className="text-gray-900 dark:text-neutral-100" />
            }
          >
            <PriceConditionStatus
              priceRange={ranges.price}
              showZeroPrice={showZeroPrice}
              selectedCondition={selectedCondition}
              selectedSaleStatuses={selectedSaleStatuses}
              showSold={showSold}
              availableFilters={{
                conditions: availableFilters.conditions || [],
                saleStatuses: availableFilters.saleStatuses || [],
                priceRange: {
                  min: RANGE_CONFIGS.priceRange.min,
                  max: RANGE_CONFIGS.priceRange.max
                }
              }}
              rangeConfigs={RANGE_CONFIGS}
              onPriceChange={(values) => onRangeChange('price', values)}
              onShowZeroPriceChange={onShowZeroPriceChange}
              onConditionChange={onConditionChange}
              onSaleStatusChange={onSaleStatusChange}
              onShowSoldChange={onShowSoldChange}
            />
          </Accordion>
          
          <Accordion 
            title="Specifications" 
            count={activeFilterCounts.specifications}
            isOpen={activeAccordion === "Specifications"}
            onToggle={(title) => setActiveAccordion(activeAccordion === title ? null : title)}
            icon={
              <Icon name="sliders-horizontal" size={20} className="text-gray-900 dark:text-neutral-100" />
            }
          >
            <Specifications
              engineTimeRange={ranges.engineTime}
              usefulLoadRange={ranges.usefulLoad}
              rangeConfigs={RANGE_CONFIGS}
              onEngineTimeChange={(values) => onRangeChange('engineTime', values)}
              onUsefulLoadChange={(values) => onRangeChange('usefulLoad', values)}
            />
          </Accordion>
          
          <Accordion 
            title="Avionics and Equipment" 
            count={activeFilterCounts.equipment}
            isOpen={activeAccordion === "Avionics and Equipment"}
            onToggle={(title) => setActiveAccordion(activeAccordion === title ? null : title)}
            icon={
              <Icon name="server" size={20} className="text-gray-900 dark:text-neutral-100" />
            }
            isLast={true}
          >
            <AvionicsAndEquipment
              selectedEquipment={equipment}
              onEquipmentChange={onEquipmentChange}
            />
          </Accordion>
        </div>
        
        {/* Footer with results and action buttons */}
        <Footer 
          totalMatches={totalMatches}
          onClearAll={handleClearAll}
          onClose={onClose}
        />
      </div>

      {/* Styles moved to their respective components */}
    </div>
  );
}); // Restore memo wrapper
