import * as React from 'react';
import type { EquipmentCategory } from '@/types/api';
import { equipmentCategories } from '@/types/api';

/**
 * Props for the AvionicsAndEquipment component
 */
export interface AvionicsAndEquipmentProps {
  /** Selected equipment states */
  selectedEquipment: Record<EquipmentCategory, boolean>;
  /** Callback for equipment changes */
  onEquipmentChange: (equipment: Record<EquipmentCategory, boolean>) => void;
}

/**
 * Component for filtering by avionics and equipment
 */
export const AvionicsAndEquipment: React.FC<AvionicsAndEquipmentProps> = ({
  selectedEquipment,
  onEquipmentChange
}) => {
  // Count active equipment selections
  const activeCount = Object.values(selectedEquipment).filter(Boolean).length;

  // Toggle a single equipment category
  const toggleEquipment = (category: EquipmentCategory) => {
    onEquipmentChange({
      ...selectedEquipment,
      [category]: !selectedEquipment[category]
    });
  };

  // Clear all equipment selections
  const clearAll = () => {
    const resetEquipment = Object.keys(selectedEquipment).reduce(
      (acc, key) => {
        acc[key as EquipmentCategory] = false;
        return acc;
      },
      {} as Record<EquipmentCategory, boolean>
    );
    onEquipmentChange(resetEquipment);
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h4 className="text-base font-medium text-gray-900 dark:text-neutral-100">
          Equipment
        </h4>
        {activeCount > 0 && (
          <button
            onClick={clearAll}
            className="text-xs text-blue-800 hover:text-blue-900 dark:text-blue-400 dark:hover:text-blue-300"
          >
            Clear All
          </button>
        )}
      </div>

      <div className="grid grid-cols-2 gap-2">
        {Object.entries(equipmentCategories).map(([category, label]) => (
          <label
            key={category}
            className="flex items-center gap-1.5 cursor-pointer"
          >
            <input
              type="checkbox"
              checked={selectedEquipment[category as EquipmentCategory]}
              onChange={() => toggleEquipment(category as EquipmentCategory)}
              className="h-3.5 w-3.5 rounded border-gray-300 dark:border-neutral-700 text-blue-800 focus:ring-blue-800 dark:bg-neutral-900"
            />
            <span className="text-xs sm:text-sm text-gray-900 dark:text-neutral-100">
              {label}
            </span>
          </label>
        ))}
      </div>
    </div>
  );
};

export default AvionicsAndEquipment;
