import * as React from 'react';
import { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';
import { useQueryStates } from 'nuqs';
import * as parsers from '../lib/parsers';
import { RANGE_CONFIGS, getRangeDefaults } from '../shared/range-config.js';

export interface ListingMetadata {
  total: number;
  totalPages: number;
}

export interface QueryState {
  search: string;
  makes: string[];
  models: string[];
  priceRange: [number, number];
  yearRange: [number, number];
  showZeroPrice: boolean;
  page: number;
  itemsPerPage: number;
  sort: string;
  view: 'grid' | 'list';
  columnCount: 1 | 2 | 3 | 4;
}

export interface ListingContextType {
  // Query state
  queryState: QueryState;
  setQueryState: (newState: Partial<QueryState>) => void;
  isFiltered: boolean;
  clearFilters: () => void;
  
  // Metadata
  metadata: ListingMetadata;
  updateMetadata: (metadata: ListingMetadata) => void;
  
  // UI helpers
  scrollToTop: () => void;
  refetchListings?: () => void;
}

const ListingContext = createContext<ListingContextType | undefined>(undefined);

export const useListingContext = () => {
  const context = useContext(ListingContext);
  if (!context) {
    throw new Error('useListingContext must be used within a ListingProvider');
  }
  return context;
};

export const ListingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  // Metadata state
  const [metadata, setMetadata] = useState<ListingMetadata>({ total: 0, totalPages: 1 });

  // Query state management with nuqs
  const [queryState, setQueryStateRaw] = useQueryStates({
    search: parsers.parseSearch,
    makes: parsers.parseMakes,
    models: parsers.parseModels,
    priceRange: parsers.parsePriceRange,
    yearRange: parsers.parseYearRange,
    showZeroPrice: parsers.parseShowZeroPrice,
    page: parsers.parsePage,
    itemsPerPage: parsers.parseItemsPerPage,
    sort: parsers.parseSort,
    view: parsers.parseView,
    columnCount: parsers.parseColumnCount
  }, {
    history: 'replace',
    throttleMs: 100,
    shallow: true
  });

  // Enhanced setQueryState that resets page when filters change
  const setQueryState = useCallback((newState: Partial<QueryState>) => {
    const isFilterUpdate = Object.keys(newState).some(key => 
      ['search', 'makes', 'models', 'priceRange', 'yearRange', 'showZeroPrice'].includes(key)
    );

    setQueryStateRaw(prev => ({
      ...prev,
      ...newState,
      // Reset page to 1 only if it's a filter change (not pagination/view change)
      page: isFilterUpdate ? 1 : (newState.page ?? prev.page)
    }));
  }, [setQueryStateRaw]);

  // Check if any filters are active
  const isFiltered = Boolean(
    queryState.search ||
    queryState.makes.length > 0 ||
    queryState.models.length > 0 ||
    queryState.showZeroPrice ||
    queryState.priceRange[0] > RANGE_CONFIGS.priceRange.min ||
    queryState.priceRange[1] < RANGE_CONFIGS.priceRange.max ||
    queryState.yearRange[0] > RANGE_CONFIGS.yearRange.min ||
    queryState.yearRange[1] < RANGE_CONFIGS.yearRange.max
  );

  // Clear all filters
  const clearFilters = useCallback(() => {
    setQueryState({
      search: '',
      makes: [],
      models: [],
      priceRange: getRangeDefaults('priceRange'),
      yearRange: getRangeDefaults('yearRange'),
      showZeroPrice: false,
      page: 1
    });
  }, [setQueryState]);

  // UI helpers
  const scrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  const updateMetadata = useCallback((newMetadata: ListingMetadata) => {
    setMetadata(newMetadata);
  }, []);

  // Reset query state to defaults
  const resetToDefaults = useCallback(() => {
    setQueryStateRaw({
      search: '',
      makes: [],
      models: [],
      priceRange: getRangeDefaults('priceRange'),
      yearRange: getRangeDefaults('yearRange'),
      showZeroPrice: false,
      page: 1,
      itemsPerPage: 20,
      sort: 'relevance',
      view: 'grid',
      columnCount: 3
    });
  }, [setQueryStateRaw]);

  // Compute active filters for debugging/display
  const activeFilters = useMemo(() => {
    const filters: string[] = [];
    
    if (queryState.search) {
      filters.push(`Search: "${queryState.search}"`);
    }
    
    if (queryState.makes?.length) {
      filters.push(`Makes: ${queryState.makes.join(', ')}`);
    }
    
    if (queryState.models?.length) {
      filters.push(`Models: ${queryState.models.join(', ')}`);
    }
    
    if (queryState.showZeroPrice) {
      filters.push('No Price Items');
    }
    
    if (queryState.priceRange[0] > RANGE_CONFIGS.priceRange.min || queryState.priceRange[1] < RANGE_CONFIGS.priceRange.max) {
      filters.push(`Price Range: $${queryState.priceRange[0].toLocaleString()} - $${queryState.priceRange[1].toLocaleString()}`);
    }
    
    if (queryState.yearRange[0] > RANGE_CONFIGS.yearRange.min || queryState.yearRange[1] < RANGE_CONFIGS.yearRange.max) {
      filters.push(`Year Range: ${queryState.yearRange[0]} - ${queryState.yearRange[1]}`);
    }
    
    return filters;
  }, [queryState]);
  
  // Debug active filters when they change
  useEffect(() => {
    if (activeFilters.length > 0) {
      // Removed console.log statements
    } else {
      // Removed console.log statements
    }
  }, [activeFilters]);

  return (
    <ListingContext.Provider 
      value={{ 
        queryState,
        setQueryState,
        isFiltered,
        clearFilters,
        metadata,
        updateMetadata,
        scrollToTop
      }}
    >
      {children}
    </ListingContext.Provider>
  );
}; 