import * as React from 'react';
import { cn, buttonStyles } from '@/lib/utils';
import { Icon } from '@/components/Icon';
import { LayoutSwitcher } from '@/components/LayoutSwitcher';
import { DarkModeToggle } from '@/components/DarkModeToggle';
import type { SortOption } from '@/types/queryState';

/**
 * Sort options configuration
 */
export const sortOptions = [
  { value: 'relevance', label: 'Most Relevant' },
  { value: 'price-asc', label: 'Price: Low to High' },
  { value: 'price-desc', label: 'Price: High to Low' },
  { value: 'year-asc', label: 'Year: Oldest' },
  { value: 'year-desc', label: 'Year: Newest' },
  { value: 'date-listed', label: 'Recently Listed' },
  { value: 'updated-desc', label: 'Recently Updated' },
  { value: 'updated-asc', label: 'Oldest Updates' },
];

/**
 * Props for the SortLayout component
 */
export interface SortLayoutProps {
  /** Current sort option */
  sort: SortOption;
  /** Callback when sort option changes */
  onSortChange: (sort: SortOption) => void;
  /** Callback when layout changes */
  onLayoutChange: (count: 1 | 2 | 3 | 4) => void;
  /** Callback when customize button is clicked */
  onCustomizeClick: () => void;
}

/**
 * Component that handles sort options and layout configuration
 */
export const SortLayout: React.FC<SortLayoutProps> = ({
  sort,
  onSortChange,
  onLayoutChange,
  onCustomizeClick
}) => {
  return (
    <div className="space-y-6">
      {/* Layout switcher, Theme toggle, and Card Attributes */}
      <div>
        <div className="flex justify-between items-baseline mb-2">
          <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Layout</h3>
        </div>
        <div className="flex items-center gap-2">
          <LayoutSwitcher
            onLayoutChange={onLayoutChange}
            variant="standard"
          />
          <div className="flex items-center gap-2">
            <button
              onClick={onCustomizeClick}
              className={cn(
                buttonStyles.base,
                buttonStyles.rounded,
                buttonStyles.shadow,
                'flex items-center justify-center h-10 px-4'
              )}
              title="Customize card attributes"
            >
              <Icon name="settings" size={16} className="text-gray-500 dark:text-neutral-400 mr-2" />
              <span className="text-sm">Card Attributes</span>
            </button>
            <DarkModeToggle variant="inline" />
          </div>
        </div>
      </div>

      {/* Sort buttons */}
      <div>
        <div className="flex justify-between items-baseline mb-2">
          <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Sort by</h3>
        </div>
        <div className="space-y-1">
          {sortOptions.map((option) => (
            <button
              key={option.value}
              onClick={() => onSortChange(option.value as SortOption)}
              className={cn(
                'inline-block text-left px-3 py-2 rounded-md text-sm transition-colors',
                sort === option.value
                  ? 'bg-blue-100 dark:bg-blue-900/30 text-blue-800 dark:text-blue-300 font-medium'
                  : 'text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-neutral-800'
              )}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SortLayout;
