import { cn, pillStyles, formatModelName, formatMakeName, formatTitleCase } from '../../../lib/utils';

export interface PillProps {
  label: string;
  count?: number;
  isSelected: boolean;
  onClick: () => void;
  disabled?: boolean;
  isModel?: boolean;
  isMake?: boolean;
}

export const Pill = ({ 
  label, 
  count, 
  isSelected, 
  onClick, 
  disabled = false, 
  isModel = false, 
  isMake = false 
}: PillProps) => {
  // Determine how to capitalize the label based on whether it's a model or make
  let capitalizedLabel;
  
  if (isModel) {
    // Special capitalization for model names
    capitalizedLabel = formatModelName(label);
  } else if (isMake) {
    // Special capitalization for make names
    capitalizedLabel = formatMakeName(label);
  } else {
    // Standard capitalization for non-model labels
    capitalizedLabel = formatTitleCase(label);
  }
  
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={cn(
        pillStyles.base,
        pillStyles.sizes.md,
        isSelected ? pillStyles.variants.active : pillStyles.variants.inactive,
        disabled && "opacity-50 cursor-not-allowed"
      )}
    >
      <span className="inline-flex items-center leading-none">
        <span className="leading-none">{capitalizedLabel}</span>
        {typeof count !== 'undefined' && count > 0 && (
          <span className={pillStyles.count}>({count})</span>
        )}
      </span>
    </button>
  );
};
