import { memo } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ListingCard } from '../ListingCard';
import { FilterUpdateIndicator } from '../FilterUpdateIndicator';
import { animations } from '../../lib/animation-config';
import type { ProductListing, CardMetric } from '../../types/api';

interface SearchResultsProps {
  listings: ProductListing[];
  isLoading: boolean;
  isLoadingComplete: boolean;
  showSpinner: boolean;
  columnCount: 1 | 2 | 3 | 4;
  visibleMetrics: string[];
  currentDetailId: string | null;
  recentlyClosedId: string | null;
  onImageClick: (listing: ProductListing, index: number) => void;
}

/**
 * SearchResults component for displaying the aircraft listings in a grid or list format
 */
export const SearchResults = memo(({
  listings,
  isLoading,
  isLoadingComplete,
  showSpinner,
  columnCount,
  visibleMetrics,
  currentDetailId,
  recentlyClosedId,
  onImageClick
}: SearchResultsProps) => {
  return (
    <>
      <AnimatePresence>
        {(showSpinner) && (
          <motion.div 
            className="fixed inset-0 flex items-center justify-center bg-white/70 dark:bg-neutral-900/70 z-[45] backdrop-blur-[1px]"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: animations.loading.duration }}
          >
            <FilterUpdateIndicator />
          </motion.div>
        )}
      </AnimatePresence>
      <motion.div 
        className={`
          grid gap-x-6 gap-y-6 flex-1 pb-10 mb-14
          ${columnCount === 1 ? 'grid-cols-1' : 
            columnCount === 2 ? 'grid-cols-2' :
            columnCount === 3 ? 'grid-cols-3' :
            'grid-cols-4'}
          relative
        `}
        layout
        animate={{ opacity: isLoadingComplete ? 1 : 0.3 }}
        transition={{ duration: animations.loading.duration }}
      >
        <AnimatePresence 
          mode="sync" 
          initial={false}
        >
          {listings.map((listing, index) => {
            // Generate a stable key that only changes when the listing content changes
            const stableKey = `${listing.aircraftId || listing._id}-${listing.price || 0}-${listing.title || ''}`;
            
            // Calculate a stagger delay that's both position and row-aware
            // This creates a more natural wave effect
            const row = Math.floor(index / (columnCount === 1 ? 1 : columnCount === 2 ? 2 : columnCount === 3 ? 3 : 4));
            const col = index % (columnCount === 1 ? 1 : columnCount === 2 ? 2 : columnCount === 3 ? 3 : 4);
            const staggerDelay = isLoadingComplete ? 
              (row * 0.02 + col * 0.03) : 0;
            
            return (
              <motion.div
                key={stableKey}
                initial={{ opacity: 0, y: animations.card.initialY }}
                animate={{ 
                  opacity: isLoadingComplete ? 1 : 0,
                  y: isLoadingComplete ? 0 : animations.card.initialY 
                }}
                exit={{ opacity: 0 }}
                transition={{ 
                  duration: animations.card.appear.duration,
                  delay: staggerDelay,
                  ease: animations.card.appear.ease
                }}
                layout
              >
                <ListingCard
                  listing={listing}
                  selectedAttributes={visibleMetrics}
                  onImageClick={onImageClick}
                  isSelected={
                    (listing.aircraftId && listing.aircraftId === currentDetailId) || 
                    (listing._id === currentDetailId) || 
                    (listing.aircraftId && listing.aircraftId === recentlyClosedId) || 
                    (listing._id === recentlyClosedId)
                  }
                />
              </motion.div>
            );
          })}
        </AnimatePresence>
      </motion.div>
    </>
  );
});
