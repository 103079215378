import * as React from 'react';
import { Pill, SinglePillGroup, PillGroup } from '@/components/common/pills';
import { RangeSlider } from '@/components/common/range-slider';
import { Toggle } from '@/components/common/toggle';
import type { Option, AvailableFilters } from '@/types/api';
import type { QueryState } from '@/types/queryState';
import { RANGE_CONFIGS } from '@/shared/range-config.js';

/**
 * Configuration for range sliders
 */
interface RangeConfig {
  min: number;
  max: number;
  step: number;
}

/**
 * Props for the PriceConditionStatus component
 */
export interface PriceConditionStatusProps {
  /** Current price range */
  priceRange: [number, number];
  /** Show zero price toggle state */
  showZeroPrice: boolean;
  /** Selected condition option */
  selectedCondition: Option | null;
  /** Selected sale statuses */
  selectedSaleStatuses: Option[];
  /** Show sold toggle state */
  showSold: boolean;
  /** Available filter options */
  availableFilters: {
    conditions: Option[];
    saleStatuses: Option[];
    priceRange?: { min: number; max: number };
  };
  /** Range configurations */
  rangeConfigs: { [key: string]: RangeConfig };
  /** Callback for price range changes */
  onPriceChange: (values: [number, number]) => void;
  /** Callback for show zero price toggle */
  onShowZeroPriceChange: (checked: boolean) => void;
  /** Callback for condition changes */
  onConditionChange: (selected: Option | null) => void;
  /** Callback for sale status changes */
  onSaleStatusChange: (selected: Option | Option[] | null) => void;
  /** Callback for show sold toggle */
  onShowSoldChange: (checked: boolean) => void;
}

/**
 * Component for filtering by price, condition, and sale status
 */
export const PriceConditionStatus: React.FC<PriceConditionStatusProps> = ({
  priceRange,
  showZeroPrice,
  selectedCondition,
  selectedSaleStatuses,
  showSold,
  availableFilters,
  rangeConfigs,
  onPriceChange,
  onShowZeroPriceChange,
  onConditionChange,
  onSaleStatusChange,
  onShowSoldChange
}) => {
  // Use local state for smoother slider interaction
  const [localPriceRange, setLocalPriceRange] = React.useState<[number, number]>(priceRange);
  
  // Keep local state in sync with props
  React.useEffect(() => {
    setLocalPriceRange(priceRange);
  }, [priceRange]);
  return (
    <div className="space-y-6">
      <div>
        <RangeSlider
          value={localPriceRange}
          onChange={(values) => {
            setLocalPriceRange(values as [number, number]);
            onPriceChange(values as [number, number]);
          }}
          min={availableFilters.priceRange?.min || rangeConfigs.priceRange.min}
          max={availableFilters.priceRange?.max || rangeConfigs.priceRange.max}
          step={rangeConfigs.priceRange.step}
          label="Price"
          formatMin={(val) => `$${val.toLocaleString()}`}
          formatMax={(val) => val === rangeConfigs.priceRange.max ? `$${val.toLocaleString()}+` : `$${val.toLocaleString()}`}
        />
        <div className="mt-4">
          <Toggle
            checked={showZeroPrice}
            onChange={onShowZeroPriceChange}
            label="Show listings without price"
            id="show-zero-price-toggle"
          />
        </div>
      </div>

      <div>
        <div className="flex justify-between items-baseline mb-2">
          <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Condition</h3>
          {selectedCondition && (
            <button
              onClick={() => onConditionChange(null)}
              className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
            >
              Clear
            </button>
          )}
        </div>
        <SinglePillGroup
          options={(availableFilters.conditions || [])}
          selectedValue={selectedCondition?.value?.toString() || null}
          onChange={onConditionChange}
          maxDisplay={10}
          emptyMessage="No condition options available"
          isModelSection={false}
        />
      </div>
      
      <div>
        <div className="flex justify-between items-baseline mb-2">
          <h3 className="text-base font-medium text-gray-900 dark:text-neutral-100">Sale Status</h3>
          {selectedSaleStatuses.length > 0 && (
            <button
              onClick={() => onSaleStatusChange(null)}
              className="text-xs text-blue-800 dark:text-blue-400 hover:underline"
            >
              Clear All
            </button>
          )}
        </div>
        <PillGroup
          options={(availableFilters.saleStatuses || [])}
          selectedValues={selectedSaleStatuses.map(s => String(s.value))}
          onChange={onSaleStatusChange}
          maxDisplay={10}
          emptyMessage="No status options available"
          isModelSection={false}
        />
        <div className="mt-4">
          <Toggle
            checked={showSold}
            onChange={onShowSoldChange}
            label="Show listings that are sold"
            id="show-sold-toggle"
          />
        </div>
      </div>
    </div>
  );
};

export default PriceConditionStatus;
